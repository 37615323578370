import React, { useState } from 'react';
import { useApp } from '../../../context/AppContext';
import { useToast } from '@/hooks/use-toast';
import { Share2, Users, Globe, Lock, ExternalLink, Award, Copy, Star } from 'lucide-react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Switch } from "@/components/ui/switch";
import EditInPlace from '../../../components/EditInPlace';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import { Card, CardContent } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import InviteUserModal from '@/components/user-app/InviteUserModal';
import ShareUsModal from '@/components/ShareUsModal';

export default function AppOverview() {
    const { app, updateApp } = useApp();
    const navigate = useNavigate();
    const { toast } = useToast();
    const [isUpdatingVisibility, setIsUpdatingVisibility] = useState(false);
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [copied, setCopied] = useState(false);

    const appLink = `/apps-show/${app.slug}`;
    const fullAppUrl = `${window.location.origin}${appLink}`;

    const handleOpenApp = () => {
        navigate(`/apps/${app.id}/editor/preview/${Object.keys(app.pages)[0]}`);
    };

    const handleAppNameChange = async (newName) => {
        if (app.status?.state !== "ready") {
            toast({
                title: "Error", 
                description: "App is still loading... name not updated",
                variant: "destructive"
            });
            return;
        }
        if (newName.length === 0) {
            toast({
                title: "Error",
                description: "App name cannot be empty",
                variant: "destructive"
            });
            return;
        }
        updateApp({name: newName});
    };

    const handleToggleStar = () => {
        updateApp({ is_starred: !app.is_starred });
        toast({
            title: app.is_starred ? "Removed from favorites" : "Added to favorites",
            description: app.is_starred ? "App removed from your starred list" : "App added to your starred list"
        });
    };

    const handleTogglePublic = async () => {
        setIsUpdatingVisibility(true);
        try {
            await updateApp({ setting_is_public: !app.setting_is_public });
            if (app.integrations_used.overall_is_using_integrations) {
                toast({
                    title: "Note about access",
                    description: "Since this app uses integrations, it will require users to log in for security purposes",
                });
            }
        } finally {
            setIsUpdatingVisibility(false);
        }
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <div className="h-full bg-gray-50 p-4 sm:p-6">
            <div className="max-w-6xl mx-auto">
                {/* Header Section */}
                <div className="space-y-6 mb-8">
                    {/* Logo and Name Section */}
                    <div className="flex flex-col sm:flex-row gap-4 sm:gap-6 items-start sm:items-center">
                        <div className="shrink-0 w-16 sm:w-20 h-16 sm:h-20 rounded-xl bg-white shadow p-3 flex items-center justify-center">
                            {app.logo_url ? (
                                <img src={app.logo_url} alt={`${app.name} logo`} className="w-12 h-12 object-contain" />
                            ) : (
                                <div className="w-12 h-12 bg-gray-100 rounded-lg flex items-center justify-center">
                                    <span className="text-xl font-bold text-gray-400">{app.name?.[0]?.toUpperCase() || 'A'}</span>
                                </div>
                            )}
                        </div>
                        <div className="flex-grow min-w-0">
                            <div className="flex items-center gap-2 flex-wrap">
                                <h1 className="text-2xl font-bold text-gray-900">
                                    <EditInPlace initialText={app.name || 'untitled'} onChange={handleAppNameChange} />
                                </h1>
                                <Button
                                    variant="ghost"
                                    size="sm"
                                    onClick={handleToggleStar}
                                    className={`hover:bg-yellow-100 transition-colors ${app.is_starred ? 'text-yellow-500' : 'text-gray-400'}`}
                                >
                                    <Star className={`h-4 w-4 ${app.is_starred ? 'fill-current' : ''}`} />
                                </Button>
                            </div>
                            <p className="text-gray-600 text-sm mt-1">{app.user_description}</p>
                            <p className="text-xs text-muted-foreground mt-1">Created {moment.utc(app.created_date).fromNow()}</p>
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex flex-wrap gap-3">
                        <Button variant="outline" onClick={handleOpenApp} className="gap-1.5 w-36 h-10">
                            <ExternalLink className="w-4 h-4" />
                            Open App
                        </Button>
                        <Button variant="outline" onClick={() => setShowShareModal(true)} className="gap-1.5 w-36 h-10">
                            <Share2 className="w-4 h-4 ml-[-8px]" />
                            <div className="flex flex-col items-start">
                                <span className='mb-[-6px]'>Share App</span>
                                <span className="text-[9px] mb-[-3px] text-gray-500">win free credits!</span>
                            </div>
                        </Button>
                    </div>
                </div>

                {/* Main Grid */}
                <div className="grid sm:grid-cols-2 gap-4">
                    {/* Public/Private Toggle Card */}
                    <Card>
                        <CardContent className="p-4">
                            <div className="flex items-start justify-between">
                                <div>
                                    <h3 className="text-base font-semibold mb-1">App Visibility</h3>
                                    <p className="text-gray-600 text-xs mb-3">
                                        Control who can access your application
                                    </p>
                                </div>
                                <Switch
                                    checked={app.setting_is_public}
                                    onCheckedChange={handleTogglePublic}
                                    disabled={isUpdatingVisibility}
                                />
                            </div>
                            <div className="mt-3 p-3 rounded-lg bg-gray-100 flex items-center gap-2">
                                {app.setting_is_public ? (
                                    <>
                                        <Globe className="w-4 h-4 text-green-500" />
                                        <span className="text-xs text-gray-600">
                                            {app.integrations_used.overall_is_using_integrations 
                                                ? "Public but requires login due to integrations"
                                                : "Your app is publicly accessible"}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <Lock className="w-4 h-4 text-orange-500" />
                                        <span className="text-xs text-gray-600">Only invited users can access</span>
                                    </>
                                )}
                            </div>
                        </CardContent>
                    </Card>

                    {/* Invite Users Card */}
                    <Card>
                        <CardContent className="p-4">
                            <div className="flex items-start justify-between mb-4">
                                <div>
                                    <h3 className="text-base font-semibold mb-1">Invite Users</h3>
                                    <p className="text-gray-600 text-xs">
                                        Grow your user base by inviting others
                                    </p>
                                </div>
                                <Users className="w-4 h-4 text-gray-400" />
                            </div>
                            <div className="flex gap-2">
                                <Button variant="outline" className="w-full h-8 text-sm" onClick={() => handleCopy(fullAppUrl)}>
                                    <Copy className="w-3 h-3 mr-1" />
                                    {copied ? 'Copied!' : 'Copy Link'}
                                </Button>
                                <Button 
                                    className="w-full h-8 text-sm bg-blue-600 hover:bg-blue-700"
                                    onClick={() => setShowInviteModal(true)}
                                >
                                    Send Invites
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>

            <InviteUserModal 
                isVisible={showInviteModal}
                onCancel={() => setShowInviteModal(false)}
                onInviteSuccess={() => setShowInviteModal(false)}
            />

            <ShareUsModal
                app={app}
                isOpen={showShareModal}
                onClose={() => setShowShareModal(false)}
            />
        </div>
    );
}
