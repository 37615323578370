import React, { useState } from 'react';
import ComponentPreview from '../../preview/ComponentPreview';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import createAppSDK from '@/api/app-sdk/AppSDK';
import AppErrors from './AppError';
import { useAuth } from '../../../context/AuthContext';


const UserNotRegisteredError = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-white to-gray-50">
            <div className="max-w-md w-full p-8 bg-white rounded-lg shadow-lg border border-gray-100">
                <div className="text-center">
                    <div className="inline-flex items-center justify-center w-16 h-16 mb-6 rounded-full bg-red-100">
                        <svg className="w-8 h-8 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                    </div>
                    <h1 className="text-3xl font-bold text-gray-900 mb-4">Access Restricted</h1>
                    <p className="text-gray-600 mb-8">
                        You are not registered to use this application. Please contact the app administrator to request access.
                    </p>
                    <div className="p-4 bg-gray-50 rounded-md text-sm text-gray-600">
                        <p>If you believe this is an error, you can:</p>
                        <ul className="list-disc list-inside mt-2 space-y-1">
                            <li>Verify you are logged in with the correct account</li>
                            <li>Contact the app administrator for access</li>
                            <li>Try logging out and back in again</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

// appId parameter is passed only when the app is accessed via a custom domain:
export default function AppDemo({appId}) {
    const {isAuthenticated, isLoadingAuth} = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const { appId : appIdParam, appSlug, pageName } = useParams();
    const [appSDK, setAppSDK] = useState(null);
    const [errors, setAppErrors] = useState([]);
    const [isUserRegistered, setIsUserRegistered] = useState(true);
    

    const onAppError = (title, details, componentName) => {
        console.log("ON APP ERROR", title, details, componentName);
        // TODO: report to parent window
        setAppErrors(prevErrors => [...prevErrors, { title, details, componentName }]);
        window.parent?.postMessage({
            type: "app_error",
            error: { title, details, componentName }
        }, "*");
    }

    const navigateToLogin = (appId) => {
        navigate(`/login?from_url=${window.location.href}&app_id=${appId}`);
    }

    useEffect(() => {
        createAppSDK({
            id: appId || appIdParam,
            slug: appSlug,
            onError: onAppError
        }).then((appSDK) => {
            setAppSDK(appSDK);
        }).catch(e => {
            console.log("ERROR FROM APP SDK", e);
            if (e.response && e.response.status === 403 && e.response.data?.extra_data?.reason) {
                if (e.response.data?.extra_data?.reason === 'auth_required') {
                    navigateToLogin(e.response.data?.extra_data?.app_id);
                } else if (e.response.data?.extra_data?.reason === 'user_not_registered') {
                    setIsUserRegistered(false);
                } 
            } else {
                onAppError({
                    title: "Failed to load app " + e.message,
                    details: "none",
                    componentName: "general"
                });
            }
        });
    }, [])

    useEffect(() => {
        window.parent.postMessage({ type: "app_changed_url", url: window.location.href }, '*');
        window.addEventListener('beforeinstallprompt', (e) => {
            e.prompt();
        });
      }, [location, pageName]);


      if (!appSDK) {
        return (
            <div className="fixed inset-0 flex items-center justify-center">
                <div className="flex flex-col items-center space-y-4">
                    <div className="w-12 h-12 border-4 border-gray-200 border-t-black rounded-full animate-spin"></div>
                </div>
            </div>
        );
      }


    if (isLoadingAuth) {
        return <div>Loading auth...</div>
    }

    if (!isUserRegistered) {
        return <UserNotRegisteredError />
    }
    

    const createComponentPreview = (componentCode, componentName, additionalComponentProps, filePath) => {
        const componentProps = {
            ...(additionalComponentProps || {})
        }
        return <ComponentPreview 
                    componentCode={componentCode}
                    componentName={componentName}
                    componentProps={componentProps}
                    filePath={filePath}
                    appSDK={appSDK}
                    onError={onAppError}
                />
    }

    let mainContent = null;

    const currentPage = appSDK.getCurrentPage();

    if (!currentPage) {
        mainContent = (
            <div className="flex flex-col items-center justify-center p-8 space-y-4 bg-red-50 border border-red-200 rounded-lg">
                <div className="text-2xl font-medium text-red-600">404 Page Not Found</div>
                <div className="text-red-500">The page "{pageName}" could not be found in this application.</div>
            </div>
        );
    } else if (errors.length > 0) {
        mainContent = <div className="min-h-full flex flex-col max-w-4xl mx-auto">
            <AppErrors appErrors={errors} isFixing={false} />
        </div>
    } else if (appSDK.integrations.getMissingPackages().length > 0) {
        mainContent = (
            <div className="flex flex-col items-center justify-center p-8 space-y-4 text-center">
                <div className="text-2xl font-light text-gray-600">Additional Integrations Needed</div>
                <div className="text-gray-500">
                    This app requires the following integrations to be connected:
                    <div className="font-medium mt-2">{appSDK.integrations.getMissingPackages().join(', ')}</div>
                </div>
                <Link 
                    to="/integrations"
                    className="btn mt-4"
                >
                    Connect Integrations
                </Link>
            </div>
        );
    } else {
        mainContent = createComponentPreview(currentPage.code, currentPage.name, {}, `pages/${currentPage.name}`);
    }

    let mainContentWithLayout = mainContent;
    if (appSDK.files.layout) {
        mainContentWithLayout = createComponentPreview(appSDK.files.layout, "Layout", {
            children: mainContent,
            currentPageName: currentPage?.name
        }, `pages/${currentPage?.name || pageName}`);
    }

    return (
        <div className="min-h-screen w-full relative">
            <div className="flex flex-col w-full h-full" id="app-demo">            
                <div className="bg-white w-full min-h-full overflow-auto">
                    <div id="component-preview-container">
                        {mainContentWithLayout}
                    </div>
                    {
                        appSDK.files.globals_css && (
                            <style>{appSDK.files.globals_css}</style>
                        )
                    }
                </div>
            </div>
        </div>
    );
}