import { useState, useEffect } from 'react';
import AppAnalytics from '@/components/user-app/AppAnalytics';
import UsageLogsAPI from '@/api/UsageLogsAPI';
import AppLogsAPI from '@/api/AppLogsAPI';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Loader2 } from "lucide-react";

function AdminAnalytics() {
    const [stats, setStats] = useState(null);
    const [trendingApps, setTrendingApps] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [statsResponse, trendingResponse] = await Promise.all([
                    UsageLogsAPI.getAdminStats(),
                    AppLogsAPI.getTrendingApps()
                ]);
                setStats(statsResponse);
                setTrendingApps(trendingResponse.trending_apps);
            } catch (error) {
                console.error('Failed to fetch admin data:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    if (isLoading) {
        return (
            <div className="h-full w-full flex items-center justify-center">
                <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
            </div>
        );
    }

    const chartData = stats?.usage_by_date?.map(item => ({
        date: item._id,
        messages: item.total_messages
    })) || [];

    const tokensByUserData = stats?.tokens_by_user_7d?.map(item => ({
        user: item._id,
        tokens: item.total_tokens
    })) || [];

    const trendingAppsData = trendingApps.map(app => ({
        name: app.name,
        visits: app.total_visits,
        users: app.unique_users
    }));

    return (
        <div className="container mx-auto py-6 space-y-6">
            <h1 className="text-2xl font-bold mb-6">Platform Analytics</h1>
            
            <Card>
                <CardHeader>
                    <CardTitle>Messages Per Day</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="h-[400px]">
                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart data={chartData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Area 
                                    type="monotone" 
                                    dataKey="messages" 
                                    stroke="#8884d8" 
                                    fill="#8884d8" 
                                    fillOpacity={0.3}
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </CardContent>
            </Card>

            <Card>
                <CardHeader>
                    <CardTitle>Top Users by Token Usage (Last 7 Days)</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="h-[400px]">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart data={tokensByUserData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="user" angle={-45} textAnchor="end" height={100} />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="tokens" fill="#82ca9d" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </CardContent>
            </Card>

            <Card>
                <CardHeader>
                    <CardTitle>Trending Apps (Last 7 Days)</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="h-[400px]">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart data={trendingAppsData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" angle={-45} textAnchor="end" height={100} />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="visits" fill="#8884d8" name="Total Visits" />
                                <Bar dataKey="users" fill="#82ca9d" name="Unique Users" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </CardContent>
            </Card>

            <AppAnalytics appId="all" />
        </div>
    );
}

export default AdminAnalytics;
