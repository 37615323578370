import React, { createContext, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu, MessageSquare, LogOut } from 'lucide-react';
import Feedback from './Feedback';
import { useAuth } from '../../context/AuthContext';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";

const TopbarContext = createContext();

export function TopbarProvider({ children }) {
  const [centerContent, setCenterContent] = useState('');
  const [rightContent, setRightContent] = useState('');
  
  return (
    <TopbarContext.Provider 
      value={{
        setCenterContent,
        setRightContent,
        centerContent,
        rightContent
      }}
    >
      {children}
    </TopbarContext.Provider>
  );
}

export const useTopbar = () => {
  return useContext(TopbarContext);
}

export const MobileTopBar = ({ pages }) => {
  const { centerContent, rightContent } = useTopbar();
  const navigate = useNavigate();
  const location = useLocation();
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const { logout } = useAuth();

  const menuItems = pages.filter(page => !page.hide).map((page) => ({
    key: page.url,
    label: (
      <div className="flex items-center gap-3">
        {React.cloneElement(page.icon, { className: "h-5 w-5" })}
        <span>{page.name}</span>
      </div>
    ),
    onClick: () => {
      navigate(page.url);
    },
  }));

  return (
    <div className="h-12 z-50 bg-background border-b">
      <div className="flex items-center h-full px-2">
        <div className="w-12">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon">
                <Menu className="h-6 w-6" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" className="w-52">
              {menuItems.map((item) => (
                <DropdownMenuItem
                  key={item.key}
                  onClick={item.onClick}
                  className={location.pathname === item.key ? 'bg-accent' : ''}
                >
                  {item.label}
                </DropdownMenuItem>
              ))}
              <DropdownMenuItem onClick={() => setFeedbackOpen(true)}>
                <div className="flex items-center gap-3">
                  <MessageSquare className="h-5 w-5" />
                  <span>Feedback</span>
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={logout}>
                <div className="flex items-center gap-3">
                  <LogOut className="h-5 w-5" />
                  <span>Logout</span>
                </div>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        <div className="flex-1 text-center">
          {centerContent}
        </div>

        <div className="w-12 flex justify-end">
          {rightContent}
        </div>
      </div>
      <Feedback isOpen={feedbackOpen} onClose={() => setFeedbackOpen(false)} />
    </div>
  );
};
