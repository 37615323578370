import { useState } from 'react';
import { Loader } from 'lucide-react';

export default function FileDisplayArea({ files, uploadingFiles, onRemoveFile }) {
    const [imagesLoaded, setImagesLoaded] = useState({});

    const handleImageLoad = (fileUrl) => {
        setImagesLoaded(prev => ({...prev, [fileUrl]: true}));
    }

    if (files.length === 0 && uploadingFiles.length === 0) return null;

    return (
        <div className="flex flex-wrap gap-4 p-4 border-b">
            {files.map((fileUrl, index) => (
                <div key={index} className="relative">
                    {fileUrl.match(/\.(jpg|jpeg|png|gif)$/i) ? (
                        <div className="relative group">
                            {!imagesLoaded[fileUrl] && <div className="w-[120px] h-[120px] flex items-center justify-center bg-gray-100 rounded-lg">
                                <Loader className="w-4 h-4 animate-spin text-gray-500" />
                            </div>}
                            <img 
                                src={fileUrl} 
                                alt="Attached content"
                                className={`max-w-[120px] max-h-[120px] object-contain rounded-lg border border-gray-200 shadow-sm ${imagesLoaded[fileUrl] ? '' : 'hidden'}`}
                                onLoad={() => handleImageLoad(fileUrl)}
                            />
                            <button 
                                onClick={() => onRemoveFile(fileUrl)}
                                className="absolute top-1 right-1 bg-white/80 text-gray-500 hover:text-gray-700 rounded-full p-1"
                            >
                                ×
                            </button>
                        </div>
                    ) : (
                        <div className="flex items-center gap-2 p-2 bg-white rounded-lg border border-gray-200 group relative">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-500">
                                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"/>
                                <polyline points="14 2 14 8 20 8"/>
                            </svg>
                            <span className="truncate max-w-[150px]">{fileUrl.split('/').pop()}</span>
                            <button 
                                onClick={() => onRemoveFile(fileUrl)}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                ×
                            </button>
                        </div>
                    )}
                </div>
            ))}
            {uploadingFiles.map((fileName, index) => (
                <div key={index} className="flex items-center gap-2 p-2 bg-white rounded-lg border border-gray-200">
                    <Loader className="w-4 h-4 animate-spin text-gray-500" />
                    <span className="truncate max-w-[150px] text-gray-500 text-sm">
                        Uploading {fileName}...
                    </span>
                </div>
            ))}
        </div>
    );
} 