import { Image } from 'lucide-react';

export default function ActionButtons({ onFileUploadClick, value, isMobile, customButtomRight, customButtomLeft }) {
    return (
        <div className="flex justify-between text-sm p-2">
            <div className="flex gap-1 items-center">
                <button
                    onClick={onFileUploadClick}
                    className="flex items-center gap-1 px-2 py-1 rounded-md text-xs text-gray-600 hover:bg-gray-100"
                    title="Upload files"
                >
                    <Image className="h-3 w-3" />
                    <span>Upload</span>
                </button>
                {customButtomLeft}
            </div>
            {value && !isMobile && <div className="flex items-center gap-1 text-xs text-gray-500">
                Press <kbd className="px-1 py-0.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg">Shift</kbd> + <kbd className="px-1 py-0.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg">Enter</kbd> for new line
            </div>}
            {!value && customButtomRight && <div className="flex items-center gap-1 text-xs text-gray-500">
                {customButtomRight}
            </div>}
        </div>
    );
} 