import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { AppProvider } from '../../context/AppContext';
import AppChat from '../../components/user-app/chat/AppChat';
import EditorMain from './EditorMain';
import { useApp } from '../../context/AppContext';
import { MessageCircle, Wand2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import SuperUserToolBar from '../../components/user-app/super-user-tools/SuperUserToolbar';
import { useAuth } from '../../context/AuthContext';

function UserAppEditor({view}) {
    const { user } = useAuth();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [showMobileChat, setShowMobileChat] = useState(false);
    const { app } = useApp();

    let mainContent = null;
    if (app.app_stage === 'pending') {
        if (app.status?.state === "processing") {
            mainContent = (
                <div className="h-full flex items-center justify-center  pt-4">
                    <div className="flex flex-col items-center space-y-6 max-w-md text-center px-4">
                        <div className="relative">
                            <div className="w-12 h-12 rounded-full bg-gray-200 animate-pulse"></div>
                            <div className="absolute top-0 left-0 w-12 h-12 rounded-full bg-gray-200 animate-ping opacity-50"></div>
                        </div>
                        <h2 className="text-xl font-light text-gray-700">Creating Your App</h2>
                        <p className="text-sm text-gray-500">Please wait while we set everything up...</p>
                    </div>
                </div>
            );
        } else {
            mainContent = (
                <div className="h-full flex items-center justify-center pt-4">
                    <div className="flex flex-col items-center space-y-6 max-w-md text-center px-4">
                        <div className="w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center" onClick={() => setShowMobileChat(true)}>
                            <Wand2 className="w-6 h-6 text-gray-400" />
                        </div>
                        <h2 className="text-xl font-light text-gray-700">Let's Build Your App</h2>
                        <p className="text-sm text-gray-500">Tell me what you'd like to create using the chat</p>
                    </div>
                </div>
            );
        }
    } else {
        mainContent = <EditorMain view={view} />;
    }

    if (isMobile) {
        return (
            <div className="h-full bg-gray-50">
                <div className="relative">
                    {mainContent}
                    <Button 
                        className="fixed bottom-6 right-6 rounded-full shadow-lg z-50 bg-gray-900 hover:bg-gray-800 flex items-center gap-2 px-4"
                        onClick={() => setShowMobileChat(true)}
                    >
                        {app.status?.state === "processing" ? (
                            <>
                                <div className="h-5 w-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                                <span className="text-white text-sm">Processing...</span>
                            </>
                        ) : (
                            <>
                                <Wand2 className="h-5 w-5 text-white" />
                                <span className="text-white text-sm">Edit App</span>
                            </>
                        )}
                    </Button>
                    <Sheet open={showMobileChat} onOpenChange={setShowMobileChat}>
                        <SheetContent side="left" className="w-[90%] sm:w-[385px] p-0">
                            <SheetHeader className="p-4 border-b">
                                <div className="flex justify-between items-center">
                                    <SheetTitle>AI App Editor</SheetTitle>
                                </div>
                            </SheetHeader>
                            <div className="flex-1 relative h-[calc(100vh-4rem)]">
                                <AppChat />
                            </div>
                        </SheetContent>
                    </Sheet>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-row h-screen bg-white">
            <div className="w-[450px] flex-shrink-0 h-full overflow-y-auto relative ps-4">
                <div className="flex flex-col gap-4 relative h-full">
                    <AppChat />
                </div>
            </div>
            <div className="flex-1 overflow-x-auto">
                {mainContent}
            </div>
            {user.platform_role === "platform_admin" && <SuperUserToolBar />}
        </div>
    );
}

export default ({view}) => <AppProvider>
    <UserAppEditor view={view} />
</AppProvider>;