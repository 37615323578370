import React, { useState, useEffect } from 'react';
import UserAppAPI from "../../api/UserAppAPI";
import AppCard from "../../components/user-app/AppCard";

function MyApps() {
    const [apps, setApps] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchApps();
    }, []);

    const fetchApps = async () => {
        setIsLoading(true);
        try {
            const appList = await UserAppAPI.list('-updated_date', 9, 0, ['id', 'name', 'user_description', 'status', 'updated_date', 'logo_url', 'created_date', 'created_by'].join(','));
            setApps(appList);
        } catch (error) {
            console.error('Failed to fetch apps:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            {isLoading ? (
                <div className="flex justify-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
                </div>
            ) : apps.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                    {apps.map((app, index) => (
                        <AppCard key={index} app={app} />
                    ))}
                </div>
            ) : (
                <div className="text-center py-12">
                    <h3 className="text-xl mb-4">You haven't created any apps yet.</h3>
                    <p className="text-muted-foreground">Start by creating your first app above!</p>
                </div>
            )}
        </div>
    );
}

export default MyApps;
