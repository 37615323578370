import { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import FilesAPI from '../../../api/FilesAPI';
import ActionButtons from './ActionButtons';
import FileDisplayArea from './FileDisplayArea';
import TextInput from './TextInput';

export default function ChatInput({
    value, 
    files = [], 
    onSend, 
    placeholder, 
    disabled, 
    isProcessing, 
    onValueChange, 
    onFilesChange, 
    onStop, 
    customButtomRight,
    customButtomLeft
}) {
    const fileInputRef = useRef();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [uploadingFiles, setUploadingFiles] = useState([]);

    const handleSend = () => {
        onSend({
            text: value,
            files: files
        });
        onValueChange('');
        onFilesChange([]);
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
        if (e.key === 'v' && e.ctrlKey) {
            handlePaste(e);
        }
    }

    const handlePaste = async (e) => {
        const items = (e.clipboardData || e.originalEvent.clipboardData).items;
        for (let item of items) {
            if (item.kind === 'file') {
                const file = item.getAsFile();
                await uploadFile(file);
            }
        }
    }

    const uploadFile = async (file) => {
        try {
            setUploadingFiles(prev => [...prev, file.name]);
            const response = await FilesAPI.uploadFile(file);
            onFilesChange([...files, response.url]);
            setUploadingFiles(prev => prev.filter(f => f !== file.name));
        } catch (error) {
            console.error('Failed to upload file:', error);
            setUploadingFiles(prev => prev.filter(f => f !== file.name));
        }
    }

    const handleFileUpload = async (e) => {
        const files = Array.from(e.target.files);
        for (let file of files) {
            await uploadFile(file);
        }
        fileInputRef.current.value = '';
    }

    const handleDrop = async (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        for (let file of files) {
            await uploadFile(file);
        }
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    }

    return (
        <div 
            className='w-full relative shadow-sm border border-gray-200 rounded-lg bg-white/80 backdrop-blur'
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            <FileDisplayArea 
                files={files} 
                uploadingFiles={uploadingFiles} 
                onRemoveFile={(fileUrl) => onFilesChange(files.filter(url => url !== fileUrl))} 
            />
            
            <TextInput 
                value={value}
                onValueChange={onValueChange}
                onKeyDown={onKeyDown}
                onPaste={handlePaste}
                placeholder={placeholder}
                disabled={disabled}
                isProcessing={isProcessing}
                onStop={onStop}
                onSend={handleSend}
            />

            <input 
                ref={fileInputRef}
                type="file" 
                id="chat-file-upload"
                className="hidden"
                multiple
                onChange={handleFileUpload}
                accept=".jpg,.jpeg,.png,.pdf"
            />

            <ActionButtons 
                onFileUploadClick={() => document.getElementById('chat-file-upload').click()}
                value={value}
                isMobile={isMobile}
                customButtomLeft={customButtomLeft}
                customButtomRight={customButtomRight}
            />
        </div>
    );
} 