import { BaseAPIModelProvider } from './base'

class AppDomainsAPI extends BaseAPIModelProvider {
    getBaseURL() {
        return '/custom-domains'
    }

    refreshStatus(domainId) {
        return this.axios.post(`/${domainId}/status`)
    }

    verify(domainId) {
        return this.axios.post(`/${domainId}/verify`)
    }
}


export default new AppDomainsAPI();
