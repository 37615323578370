import Home from "./home/HomePage";
import UserSettings from "./UserSettings";
import UserApps from "./UserApps";
import { Home as HomeIcon, Edit, Settings, Code, MessageSquare, Layers, Plug2, Plus, CreditCard, Users, BarChart } from 'lucide-react';
import UserAppEditor from "./app-editor/UserAppEditor";
import AppDemo from "../components/user-app/demo/AppDemo";
import CreateAppPage from "./CreateApp";
import Billing from "./billing/Billing";
import UserManagement from "./admin/UserManagement";
import AdminAnalytics from "./admin/AdminAnalytics";
import Login from "./login/Login.js";
import PrivacyPolicy from "./legal/PrivacyPolicy";
import TermsOfService from "./legal/TermsOfService";
import { BACKEND_URL } from '../config.js';
import { useParams } from 'react-router-dom';
import PageNotFound from "./PageNotFound";



function NavigateToFile(){
  const { fileId } = useParams();
  window.location.href = `${BACKEND_URL}/api/files/${fileId}`;
}

function ProxyPageComponent({urlChangeHandler}) {
    const currentUrl = window.location.pathname;
    const newUrl = urlChangeHandler(currentUrl);
    console.log("ProxyPageComponent", currentUrl, newUrl);
    if (newUrl !== currentUrl) {
        window.location.href = newUrl;
    }
    return <div>Loading...</div>;
}

const pages = [
    { 
        name: 'Home', 
        url: '/', 
        component: <Home />, 
        icon: <HomeIcon /> 
    },
    // utils:
    {
        name: "Login",
        url: "/login",
        component: <Login />,
        requiresAuth: false,
        usesLayout: false,
        hide: true
    },
    {
        name: "PrivacyPolicy",
        url: "/privacy-policy",
        component: <PrivacyPolicy />,
        requiresAuth: false,
        hide: true
    },
    {
        name: "TermsOfService",
        url: "/terms-of-service",
        component: <TermsOfService />,
        requiresAuth: false,
        hide: true
    },
    {
        name: "File",
        url: "/api/files/:fileId",
        component: <NavigateToFile />,
        requiresAuth: false,
        usesLayout: false,
        hide: true
    },

    // Platform content
    {
        name: 'User Apps', 
        url: '/user-apps/:appId/show/:pageName?', 
        component: <ProxyPageComponent urlChangeHandler={(url) => url.replace('/user-apps/', '/apps/')} />, 
        icon: <Layers />,
        showInMenu: false,
        requiresAuth: false
    },
    { 
        name: 'User Apps', url: '/apps', component: <UserApps />, icon: <Layers />,
        children: [
            {
                name: 'User App Dashboard', 
                url: '/apps/:appId', 
                component: <UserAppEditor />, 
                icon: <Layers />,
            },
            { 
                name: 'Show User App', 
                url: '/apps/:appId/show/:pageName?', 
                component: <AppDemo />, 
                icon: <Code />,
                usesLayout: false,
                requiresAuth: true
            },
            {
                name: "App Show",
                url: "/apps-show/:appSlug/:pageName?",
                component: <AppDemo />,
                icon: <Code />,
                usesLayout: false,
                requiresAuth: false
            },
            { 
                name: 'Edit User App', 
                url: '/apps/:appId/editor/preview/:pageName', 
                component: <UserAppEditor view="preview" />, 
                icon: <Edit />,
            },
            { 
                name: 'Edit User App', 
                url: '/apps/:appId/dashboard', 
                component: <UserAppEditor view="workspace" />, 
                icon: <Edit />,
            },
            { 
                name: 'Edit User App', 
                url: '/apps/:appId/editor/preview', 
                component: <UserAppEditor view="preview" />, 
                icon: <Edit />,
            },
            { 
                name: 'Edit User App', 
                url: '/apps/:appId/editor/workspace/:workspaceView', 
                component: <UserAppEditor view="workspace" />, 
                icon: <Edit />,
            },
            {
                name: 'Create App',
                url: '/create-app',
                component: <CreateAppPage />,
                icon: <Plus />,
            }
        ]
    },
    {
        name: 'Billing',
        url: '/billing',
        component: <Billing />,
        icon: <CreditCard />,
        hide: true
    },
    { 
        name: 'User Settings', 
        url: '/user-settings',
        hide: true,
        component: <UserSettings />,
        icon: <Settings /> 
    },
    {
        name: 'User Management',
        url: '/user-management',
        component: <UserManagement />,
        icon: <Users />,
        hide: true
    },
    {
        name: 'Analytics',
        url: '/admin/analytics',
        component: <AdminAnalytics />,
        icon: <BarChart />,
        hide: true
    },
    {
        name: "NotFound",
        url: "*",
        component: <PageNotFound />,
        hide: true, 
        usesLayout: false,
        requiresAuth: false
    }
    // {
    //     name: 'Integrations',
    //     url: '/integrations',
    //     component: <IntegrationsPage />,
    //     icon: <Plug2 /> 
    // }
]


// const customDomainPages = [
//     {
//         name: "Home",
//         url: "/",
//         component: <AppDemo />,
//         icon: <HomeIcon />
//     }
// ]
  
export {
    pages,
    // customDomainPages
};