import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserAppAPI from '../api/UserAppAPI';

export default function CreateAppPage() {
    // extract the prompt from the url parameters (e.g. /create-app?prompt=...)
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const prompt = searchParams.get('prompt');
    const [error, setError] = useState(null);

    useEffect(() => {
        async function createApp() {
            if (prompt) {
                try {
                    const newApp = await UserAppAPI.create({
                        status: {state: "processing", details: "Starting conversation..."}
                    });
                    UserAppAPI.addMessage(newApp.id, {content: prompt});
                    navigate(`/apps/${newApp.id}/editor/workspace/preview`);
                } catch (err) {
                    console.error("Error creating app:", err);
                    setError(err.message || "Error creating app");
                }
            } else {
                setError("No prompt provided");
            }
        }
        createApp();
    }, [prompt, navigate]);

    if (error) {
        return <div className="flex items-center justify-center h-screen">
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
                <span>{error}</span>
            </div>
        </div>;
    }

    return <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-gray-300 border-t-blue-600"></div>
    </div>;
}