import { Send, Square } from 'lucide-react';

export default function TextInput({ 
    value, 
    onValueChange, 
    onKeyDown, 
    onPaste, 
    placeholder, 
    disabled, 
    isProcessing, 
    onStop, 
    onSend 
}) {


    const onClickButton = () => {
        if (isProcessing) {
            // onStop();
        } else {
            onSend();
        }
    }
    return (
        <div className="relative">
            <textarea
                value={value}
                onChange={(e) => onValueChange(e.target.value)}
                onKeyDown={onKeyDown}
                onPaste={onPaste}
                placeholder={placeholder}
                disabled={disabled}
                className="w-full pl-4 pt-4 pr-16 text-sm resize-none overflow-auto focus:outline-none bg-transparent rounded-t-lg"
                style={{
                    height: 'auto',
                    minHeight: '76px',
                    maxHeight: '400px'
                }}
                onInput={e => {
                    e.target.style.height = 'auto';
                    if (e.target.scrollHeight > 400) {
                        e.target.style.height = '400px';
                    } else {
                        e.target.style.height = e.target.scrollHeight + 'px';
                    }
                }}
            />
            {(value && !isProcessing) && (
                <button 
                    onClick={onClickButton}
                    className="absolute flex justify-center items-center top-4 right-4 p-1 bg-blue-500 hover:bg-blue-600 text-white rounded-md w-[34px] h-[34px] transition-colors"
                >
                    {isProcessing ? (
                        <Square className="w-4 h-4" />
                    ) : (
                        <Send className="w-4 h-4" />
                    )}
                </button>
            )}
        </div>
    );
} 