import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserAppAPI from "../../api/UserAppAPI";
import logoImage from '../../logo_v2.png';
import { Card } from "@/components/ui/card";
import TrendingApps from './TrendingApps';
import AppConversationStarter from './AppConversationStarter';
import MyApps from './MyApps';
import ShareUsModal from '@/components/ShareUsModal';
import { Button } from '@/components/ui/button';
import { Share2 } from 'lucide-react';

function Home() {
    const [isCreating, setIsCreating] = useState(false);
    const [activeTab, setActiveTab] = useState('my-apps');
    const [showShareModal, setShowShareModal] = useState(false);
    const navigate = useNavigate();

    const handleCreateApp = async ({text, files}) => {
        if (!text.trim()) {
            alert('Please enter an app description');
            return;
        }
        setIsCreating(true);
        try {
            const newApp = await UserAppAPI.create({
                status: {state: "processing", details: "Starting conversation..."}
            });
            UserAppAPI.addMessage(newApp.id, {
                content: text, file_urls: files
            });
            navigate(`/apps/${newApp.id}/editor/preview`);
        } catch (error) {
            console.error('Failed to create app:', error);
            alert('Failed to create app. Please try again.');
        } finally {
            setIsCreating(false);
        }
    };

    return (
        <div className="container mx-auto px-4 py-8 max-w-4xl">
            <div onClick={() => setShowShareModal(true)} className="cursor-pointer bg-white border border-lightgray rounded-lg p-3 mb-6 mt-6 flex items-center justify-between hover:border-orange-200/50 transition-colors duration-200">
                <div className="flex items-center space-x-3">
                    <Share2 className="h-4 w-4 text-teal-500" />
                    <p className="text-sm text-gray-600">
                        <span className="text-orange-500 font-medium">Win free credits</span> by sharing your creation on social media!
                    </p>
                </div>
                <span 
                    className="text-sm text-teal-500 hover:text-[#507375] cursor-pointer font-medium">
                    Share →
                </span>
            </div>

            <Card className="p-6 mb-12">
                <div>
                    <h2 className="text-2xl font-bold flex items-center mb-4">
                        <img src={logoImage} alt="Base44 Logo" className="w-8 h-8 mr-2" />
                        What would you build today?
                    </h2>
                    <AppConversationStarter onStart={handleCreateApp} loading={isCreating} />
                </div>
            </Card>

            <div className="flex justify-center mb-8">
                <div className="inline-flex gap-8 text-lg">
                    <button
                        onClick={() => setActiveTab('my-apps')}
                        className={`pb-2 px-2 transition-all duration-200 border-b-2 ${
                            activeTab === 'my-apps' 
                            ? 'border-primary text-primary font-medium' 
                            : 'border-transparent text-muted-foreground hover:text-foreground'
                        }`}
                    >
                        My Recent Apps
                    </button>
                    {/* <button
                        onClick={() => setActiveTab('trending')}
                        className={`pb-2 px-2 transition-all duration-200 border-b-2 ${
                            activeTab === 'trending' 
                            ? 'border-primary text-primary font-medium' 
                            : 'border-transparent text-muted-foreground hover:text-foreground'
                        }`}
                    >
                        Trending
                    </button> */}
                </div>
            </div>

            <div className="transition-opacity duration-200">
                {activeTab === 'my-apps' && <MyApps />}
                {activeTab === 'trending' && <TrendingApps />}
            </div>

            <ShareUsModal 
                isOpen={showShareModal}
                onClose={() => setShowShareModal(false)}
            />
        </div>
    );
}

export default Home;
