import React, { useState } from 'react';
import ChatMarkdown from './ChatMarkdown';

function AutomatedFixMessage({message}) {
    const [showErrors, setShowErrors] = useState(false);
    return (
        <div className="text-sm p-4 bg-white mx-auto mb-6 rounded-lg mb-6 border border-gray-300">
            <div className="text-gray-700">
                Some errors were detected, base44 will try to fix them automatically.{' '}
                <div className="mt-2">
                    <a className="text-teal-900/70 hover:underline cursor-pointer" onClick={() => setShowErrors(!showErrors)}>
                    {showErrors ? 'hide errors' : 'see errors'}
                </a></div>
            </div>
            {showErrors && (
                <div className="mt-4">
                    <ChatMarkdown content={message.content} />
                </div>
            )}
        </div>
    );
}

export default AutomatedFixMessage;
