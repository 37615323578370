import React, { useState, useEffect } from 'react';
import { TrendingUp } from 'lucide-react';
import AppLogsAPI from '../../api/AppLogsAPI';
import AppCard from '../../components/user-app/AppCard';

export default function TrendingApps() {
    const [trendingApps, setTrendingApps] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchTrendingApps = async () => {
            try {
                const response = await AppLogsAPI.getTrendingApps();
                setTrendingApps(response.trending_apps.map(app => ({
                    ...app,
                    created_by: 'Community',
                })));
            } catch (error) {
                console.error('Failed to fetch trending apps:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTrendingApps();
    }, []);

    if (isLoading) {
        return (
            <div className="mt-12">
                <h2 className="text-2xl font-bold mb-6 flex items-center">
                    <TrendingUp className="w-6 h-6 mr-2" />
                    Trending Apps
                </h2>
                <div className="flex justify-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
                </div>
            </div>
        );
    }

    if (!trendingApps.length) {
        return null;
    }

    return (
        <div className="mt-12">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                {trendingApps.map((app) => (
                    <AppCard key={app.id} app={app} linkToBuilder={false} />
                ))}
            </div>
        </div>
    );
}
