import { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { X, Twitter, Linkedin, Copy, Share2 } from "lucide-react";

const ShareUsModal = ({ app, isOpen, onClose }) => {
  const handleShare = (platform) => {
    const text = app 
      ? `I've built ${app.name} with @base_44!`
      : "Check out what I built with @base_44!";
    const url = app ? `https://app.base44.com/apps-show/${app.slug}` : window.location.href;

    if (platform === 'twitter') {
      window.open(`https://x.com/intent/tweet?text=${encodeURIComponent(text)}`);
    } else if (platform === 'linkedin') {
      window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[625px]">
        <DialogHeader>
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <Share2 className="h-5 w-5 text-primary" />
              <DialogTitle className="text-xl">
                {app ? `Share ${app.name}` : 'Share Project'}
              </DialogTitle>
            </div>
          </div>
          <DialogDescription className="text-sm text-muted-foreground">
            Share your project and earn 20 free credits to keep building!
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-6">
          <div className="bg-secondary/20 p-4 rounded-lg space-y-3 border border-border">
            <h3 className="font-medium text-sm">Sharing Guidelines</h3>
            <ul className="space-y-2.5 text-sm text-muted-foreground">
              <li className="flex items-center gap-2">
                <div className="h-1.5 w-1.5 rounded-full bg-primary/70" />
                Tag <span className="font-medium text-foreground">@base_44</span>
              </li>
              <li className="flex items-center gap-2">
                <div className="h-1.5 w-1.5 rounded-full bg-primary/70" />
                Write at least 200 characters about your experience building with Base44
              </li>
              <li className="flex items-center gap-2">
                <div className="h-1.5 w-1.5 rounded-full bg-primary/70" />
                Add a screenshot or video of your project (optional)
              </li>
              <li className="flex items-center gap-2">
                <div className="h-1.5 w-1.5 rounded-full bg-primary/70" />
                Add a link to your project (optional)
              </li>
            </ul>
          </div>

          <div className="flex flex-col gap-2">
            <Button 
              variant="outline"
              className="w-full justify-start gap-2 font-normal"
              onClick={() => handleShare('linkedin')}
            >
              <Linkedin className="h-4 w-4" />
              Share on LinkedIn
            </Button>
            
            <Button 
              variant="outline"
              className="w-full justify-start gap-2 font-normal"
              onClick={() => handleShare('twitter')}
            >
              <Twitter className="h-4 w-4" />
              Share on X (Twitter)
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ShareUsModal;
