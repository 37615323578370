import { useApp } from "../../../context/AppContext";
import Chat from "../../chat/Chat";
import UsageAndSupport from "./UsageAndSupport";
import SelectElement from "./SelectElement";
import { useLocation } from "react-router-dom";


export default function AppChat() {
    const { app, addChatMessage, editChatMessage, undoMessage, stopChat, suggestions, currentUsage } = useApp();
    const location = useLocation();

    const isDisabled = currentUsage ? 
        (currentUsage.daily_usage >= (currentUsage.daily_limit || 1000000) || currentUsage.monthly_usage >= (currentUsage.monthly_limit || 1000000)) 
        : false;

    const shouldHideSelectElement = (!Object.keys(app.pages).length || !location.pathname.includes('preview') || window.innerWidth < 768);

    return (
        <div className="h-full absolute w-full">
            <Chat 
                chat={app.conversation} 
                addMessage={addChatMessage} 
                status={app.status} 
                editMessage={editChatMessage} 
                undoMessage={undoMessage} 
                onStop={stopChat} 
                suggestions={suggestions}
                isDisabled={isDisabled}
                customButtomRight={<UsageAndSupport currentUsage={currentUsage} isDisabled={isDisabled} />}
                customButtomLeft={shouldHideSelectElement ? null  : <SelectElement />}
            />
        </div>
    )
}