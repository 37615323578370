import './index.css';
import './App.css';

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext.js';
import { pages } from './pages/index.js';
import Layout from './components/layout/Layout.js';
import _ from 'lodash';
import { Toaster } from 'react-hot-toast';
import { Loader2 } from "lucide-react";
import { Navigate } from "react-router-dom";
import CustomDomainApp from './components/user-app/CustomDomainApp.js';

function Page({page}){
  const requiresAuth = !(page.requiresAuth === false);
  const usesLayout = !(page.usesLayout === false);
  const pagesInMenu = pages.filter(p => p.showInMenu !== false);
  let element = usesLayout ? <Layout pages={pagesInMenu}>{page.component}</Layout> : page.component;
  if (requiresAuth) {
    element = <ProtectedRoute>{element}</ProtectedRoute>
  }

  return element;
}


const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoadingAuth } = useAuth();

  if (isLoadingAuth) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }
  
  if (!isAuthenticated) {
    console.log("navigating to login page");
    const currentUrl = encodeURIComponent(window.location.href);
    const host = window.location.hostname;
    if (host !== "app.base44.com" && host !== "localhost" && host !== "base44.com"){
      window.location.href = `https://app.base44.com/login?from_url=${currentUrl}`;
    }
    return <Navigate to={`/login?from_url=${currentUrl}`} replace />;
  }
  
  return children;
};


const AppInner = () => {
  const flatPages = _.flatMapDeep(pages, page => [page, ...(page.children || [])]);
  return (
      <Router>
        <AuthProvider>
          <Routes>
            
            {
              flatPages.map(p => <Route 
                key={p.url} 
                path={p.url} 
                element={<Page page={p} />} 
                />
              )
              }
          </Routes>
        </AuthProvider>
      </Router>
  );
};


const App = () => {
  const appDomains = ["localhost", "base44.com", "app.base44.com", "base44.onrender.com", "apper-test.onrender.com"]
  const host = window.location.hostname;

  let mainComponent = <AppInner />
  if (!appDomains.includes(host)) {
    mainComponent = <CustomDomainApp />
  }

  return (
    <div className="font-sans">
      <div><Toaster/></div>
      {mainComponent}
    </div>
  );
};

export default App;