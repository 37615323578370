import { useEffect, useState, useRef } from "react"
import ToggleWithText from "../../components/ToggleWithText"
import AppDemo from "../../components/user-app/demo/AppDemo"
import EditorWorkspace from "./EditorWorkspace"
import { useApp } from '../../context/AppContext';
import { useNavigate, Link } from 'react-router-dom';
import { MoreVertical, RotateCw, X, Circle, Share, UserPlus } from 'lucide-react';
import { useMediaQuery } from 'react-responsive';
import { useTopbar } from "../../components/layout/MobileTopBar"
import { Button } from "../../components/ui/button"
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "../../components/ui/dropdown-menu"
import _ from 'lodash';
import InviteUserModal from "../../components/user-app/InviteUserModal";

const MockBrowser = ({ children, middleElement, rightElement }) => {
  return (
    <div className="flex flex-col h-full bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
      {/* Browser chrome */}
      <div className="flex items-center justify-between px-4 py-1 bg-gray-100 border-b border-gray-200">
        {/* Traffic lights */}
        <div className="flex items-center gap-1.5">
          <Circle className="w-3 h-3 text-red-500 fill-red-500" />
          <Circle className="w-3 h-3 text-yellow-500 fill-yellow-500" />
          <Circle className="w-3 h-3 text-green-500 fill-green-500" />
        </div>
        
        {/* Middle element */}
        <div className="flex-1 flex justify-center">
          {middleElement}
        </div>

        {/* Right element */}
        <div>
          {rightElement}
        </div>
      </div>

      {/* Content */}
      <div className="flex-1 overflow-auto">
        {children}
      </div>
    </div>
  )
}

export default function EditorMain({view}) {
    const { app, appErrors, addChatMessage, setAppErrors, onAppError, isElementMode, setSelectedElement } = useApp();
    const iframeRef = useRef(null);
    const [isFixing, setIsFixing] = useState(false);
    const [showInviteModal, setShowInviteModal] = useState(false);
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const { setCenterContent, setRightContent } = useTopbar();

    const refreshIframe = () => {
        if (iframeRef.current) {
            iframeRef.current.contentWindow.location.reload();
        }
    };

    useEffect(() => {
        if (iframeRef.current) {
            refreshIframe();
        }
    }, [app.app_code_hash]);

    

    useEffect(() => {
        if (app.slug && iframeRef.current && !iframeRef.current.src.includes(app.slug)) {
            iframeRef.current.src = `/apps-show/${app.slug}`;
        }
        window.addEventListener('message', onWindowMessage);
        return () => {
            window.removeEventListener('message', onWindowMessage);
        }

    }, [app.slug, iframeRef?.current]);

    const tryFixError = async () => {
        setIsFixing(true);
        
        const msgToString = (msg) => {
            const escapeTitle = _.escape(msg.title);
            const escapeDetails = _.escape(msg.details || 'no details');
            const escapeComponentName = _.escape(msg.componentName);
            let msgString = `\n\n<error title="${escapeTitle}" details="${escapeDetails}" component-name="${escapeComponentName}"></error>`;
            return msgString + '\n\n';
        }

        const allMessagesAsString = appErrors.map((error) => msgToString(error)).join('\n\n');
        const errorAsString = `the following errors happened in the app:\n${allMessagesAsString}\n---\nplease help me fix these errors`;
        await addChatMessage({"role": "user", "content": errorAsString});
        setIsFixing(false);
    }


    const onWindowMessage = (msg) => {
        if (msg.data?.type === "app_error" && msg.data.error) {
            console.log("onWindowMessage", msg.data.error);
          onAppError(msg.data.error.title, msg.data.error.details, msg.data.error.componentName);
        }
        if (msg.data?.type === "app_changed_url" && msg.data.url && window.location.href.includes('/preview')) {
          const lastUrlPart = msg.data.url.split('/').pop();
          const currentLastUrlPart = window.location.href.split('/').pop();
          if (lastUrlPart === app.slug) {
            navigate(`/apps/${app.id}/editor/preview/${Object.keys(app.pages)[0]}`);
          } else if (lastUrlPart !== currentLastUrlPart) {
            navigate(`/apps/${app.id}/editor/preview/${lastUrlPart}`);
          }
        }
      }

    const handleViewChange = (view) => {
        if (view.toLowerCase() === "workspace") {
            navigate(`/apps/${app.id}/editor/workspace/overview`);
        } else {
            navigate(`/apps/${app.id}/editor/preview/${Object.keys(app.pages)[0]}`);
        }
    }

    const ToggleElement = <ToggleWithText option1="Workspace" option2="Preview" selected={view === 'preview' ? 'Preview' : 'Workspace'} onChange={handleViewChange} />

    useEffect(() => {
        setCenterContent(ToggleElement);
        setRightContent(
            isMobile ? (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="icon">
                            <MoreVertical className="h-5 w-5" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuItem onClick={refreshIframe}>
                            <RotateCw className="mr-2 h-4 w-4" />
                            Refresh
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => setShowInviteModal(true)}>
                            <UserPlus className="mr-2 h-4 w-4" />
                            Share
                        </DropdownMenuItem>
                        <DropdownMenuItem asChild>
                            <Link to={`/apps-show/${app.slug}`} target="_blank">
                                <Share className="mr-2 h-4 w-4" />
                                Publish
                            </Link>
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            ) : (
                <div className="flex items-center gap-2">
                    <button onClick={refreshIframe} className="p-1.5 text-gray-500 hover:bg-gray-200 rounded-md transition-colors">
                        <RotateCw size={20} />
                    </button>
                    <button onClick={() => setShowInviteModal(true)} className="p-1.5 text-gray-500 hover:bg-gray-200 rounded-md transition-colors">
                        <UserPlus size={20} />
                    </button>
                    <Link to={`/apps-show/${app.slug}`} target="_blank" className="px-3 py-1 bg-gray-900 text-white text-sm rounded-md hover:bg-gray-800 transition-colors">
                        Publish
                    </Link>
                </div>
            )
        );
        return () => {
            setCenterContent(null);
            setRightContent(null);
        }
    }, [isMobile, view]);

    const setCurrentPage = (pageName, urlParams) => {
        navigate(`/apps/${app.id}/editor/preview/${pageName}?${new URLSearchParams(urlParams).toString()}`);
    }

    if (isMobile) {
        return <div className="flex-grow h-full overflow-y-auto">
            {view === "preview" ? <AppDemo setPage={setCurrentPage} /> : <EditorWorkspace />}
        </div>
    }

    const rightToolbarElement = (
        <div className="flex items-center gap-2">
            <button onClick={refreshIframe} className="p-1.5 text-gray-500 hover:bg-gray-200 rounded-md transition-colors">
                <RotateCw size={20} />
            </button>
            <button onClick={() => setShowInviteModal(true)} className="p-1.5 text-gray-500 hover:bg-gray-200 rounded-md transition-colors">
                <UserPlus size={20} />
            </button>
            <Link to={`/apps-show/${app.slug}`} target="_blank" className="px-3 py-1.5 bg-gray-900 text-white text-sm rounded-md hover:bg-gray-800 transition-colors">
                Publish
            </Link>
        </div>
    )

    return <div className="flex-grow h-full overflow-y-auto p-4">
        <MockBrowser 
            middleElement={ToggleElement}
            rightElement={rightToolbarElement}
        >
            {view === "preview" ? <iframe ref={iframeRef} id="preview-iframe" className="w-full h-full" /> : <EditorWorkspace />}
        </MockBrowser>
        
        <InviteUserModal 
            isVisible={showInviteModal}
            onCancel={() => setShowInviteModal(false)}
            onInviteSuccess={() => setShowInviteModal(false)}
        />
        
        {appErrors.length > 0 && (
            <div className="fixed bottom-4 right-4 z-50 bg-white rounded-lg shadow-xl p-6 max-w-md border border-gray-100">
                <div className="flex flex-col gap-3">
                    <div className="flex justify-between items-center">
                        <div className="font-medium text-gray-800">Issues Found</div>
                        <button 
                            onClick={() => setAppErrors([])} 
                            className="p-1 hover:bg-gray-100 rounded-md"
                        >
                            <X size={16} />
                        </button>
                    </div>
                    {appErrors.map((error) => (
                        <div key={error.title} className="text-sm text-gray-600">
                            In {error.componentName} component: {error.title}
                            </div>
                    ))}
                    <button 
                        onClick={() => tryFixError()} 
                        disabled={app.status?.state === 'processing' || isFixing}
                        className={`px-4 py-2 bg-gray-900 hover:bg-gray-800 text-white text-sm rounded-md transition-colors mt-2 ${app.status?.state === 'processing' ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        Resolve with AI
                    </button>
                </div>
            </div>
        )}
    </div>
}