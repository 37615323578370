import { useState, useEffect, useRef } from 'react';
import { Copy } from 'lucide-react';
import { toast } from 'react-hot-toast';
import ChatInput from './chat-input/AppChatInput';
import LoadingDots from '../LoadingDots';
import { useAuth } from '@/context/AuthContext';
import ChatMessage from './ChatMessage';

const MESSAGES_PER_PAGE = 50;

const ChatErrorComponent = ({ status }) => {
    return (
        <div className="mx-4 my-3 px-4 py-3 rounded-lg bg-red-50 border border-red-200 text-red-700 text-sm flex items-center">
            <div className="flex-1">
                {status.details}
            </div>
        </div>
    );
}

const MessagesList = ({ messages, editMessage, undoMessage, submitUserInputTool, status, errorComponent }) => {
    const [visibleMessages, setVisibleMessages] = useState(MESSAGES_PER_PAGE);
    const [hasMore, setHasMore] = useState(true);
    const messagesContainerRef = useRef(null);

    useEffect(() => {
        // Reset pagination when messages change
        setVisibleMessages(MESSAGES_PER_PAGE);
        setHasMore(messages.length > MESSAGES_PER_PAGE);
    }, [messages]);

    const loadMore = () => {
        // Save current scroll position
        const scrollContainer = messagesContainerRef.current;
        const scrollPosition = scrollContainer.scrollHeight - scrollContainer.scrollTop;

        // Load all remaining messages at once
        setVisibleMessages(messages.length);
        setHasMore(false);

        // Restore scroll position after render
        setTimeout(() => {
            scrollContainer.scrollTop = scrollContainer.scrollHeight - scrollPosition;
        }, 0);
    };

    const displayedMessages = messages.slice(-visibleMessages);

    return (
        <div ref={messagesContainerRef}>
            {hasMore && (
                <div className="flex justify-center py-2">
                    <button 
                        onClick={loadMore}
                        className="text-sm text-blue-500 hover:text-blue-600 hover:underline"
                    >
                        Load entire conversation
                    </button>
                </div>
            )}
            
            {displayedMessages.map((item, index) => (
                <ChatMessage 
                    key={item.id || index} 
                    item={item} 
                    editMessage={editMessage} 
                    undoMessage={undoMessage} 
                    submitUserInputTool={submitUserInputTool} 
                />
            ))}

            {status.state === "error" && errorComponent(status)}
            
            {status.state === 'processing' && (
                <div className="flex justify-center text-sm py-4">
                    <LoadingDots />
                </div>
            )}
        </div>
    );
};

function Chat({
    chat, 
    addMessage, 
    editMessage, 
    undoMessage, 
    submitUserInputTool, 
    status, 
    onStop, 
    errorComponent=(status) => <ChatErrorComponent status={status} />, 
    suggestions=null, 
    isDisabled=false, 
    customButtomRight=null,
    customButtomLeft=null
}) {
    const { user } = useAuth();
    const [messageContentString, setMessageContentString] = useState('');
    const [fileUrls, setFileUrls] = useState([]);
    const chatContainerRef = useRef(null);

    const isAdmin = user.platform_role?.includes('admin');

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    const handleSend = async () => {
        if (messageContentString.trim() && status.state !== "processing") {
            try {
                const newMessage = {
                    content: messageContentString,
                    file_urls: fileUrls
                }
                
                setMessageContentString('');
                setFileUrls([]);
                await addMessage(newMessage);
            } catch (err) {
                console.error(err);
                toast.error('Failed to send message');
            }
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setMessageContentString(suggestion.prompt);
    }

    const handleUndo = (messageId) => {
        const msg = chat.messages.find(m => m.id === messageId);
        if (msg.role === "user") {
            undoMessage(messageId);
            setMessageContentString(msg.content);
        }
    }

    // Scroll on initial load and when messages change
    useEffect(() => {
        scrollToBottom();
    }, [chat?.messages]);

    // Scroll when processing status changes
    useEffect(() => {
        scrollToBottom();
    }, [status]);

    // Set up mutation observer to detect content changes and scroll
    useEffect(() => {
        if (!chatContainerRef.current) return;

        const observer = new MutationObserver(scrollToBottom);
        
        observer.observe(chatContainerRef.current, {
            childList: true,
            subtree: true,
            characterData: true
        });

        return () => observer.disconnect();
    }, []);

    if (!chat) return <div className="alert alert-warning m-4 text-sm">No chat found</div>;

    const copyAllMessagesToClipboard = () => {
        const lastEightMessages = chat.messages.slice(-8);
        const messageText = lastEightMessages.map(m => `${m.role}:\n${m.content}`).join('\n\n');
        navigator.clipboard.writeText(messageText);
    }

    const filteredMessages = chat.messages.filter(m => m.hidden !== true);

    return (
        <div className="flex flex-col h-full w-full sm:pb-4">
            <div ref={chatContainerRef} className="flex-grow overflow-y-auto py-2 space-y-2">
                <MessagesList 
                    messages={filteredMessages}
                    editMessage={editMessage}
                    undoMessage={undoMessage ? handleUndo : null}
                    submitUserInputTool={submitUserInputTool}
                    status={status}
                    errorComponent={errorComponent}
                />
            </div>

            {status.state === "ready" && suggestions && <>
                <div className="text-[10px] text-gray-400 text-center mb-1">Suggestions</div>
                <div className="flex justify-center gap-3 px-4 py-2 border-t border-gray-100">
                    {[suggestions.suggestion_1, suggestions.suggestion_2, suggestions.suggestion_3].map((suggestion, index) => (
                        <span 
                            key={index}
                            className="text-xs text-gray-400 hover:text-gray-600 cursor-pointer transition-colors duration-200 hover:underline px-2 py-1 rounded-full hover:bg-gray-50" 
                            onClick={() => handleSuggestionClick(suggestion)}
                        >
                            {suggestion.title}
                        </span>
                    ))}
                </div>
            </>}
            
            <div className="relative">
                <div className="join w-full">
                    <ChatInput
                        value={messageContentString}
                        onValueChange={setMessageContentString}
                        files={fileUrls}
                        onFilesChange={setFileUrls}
                        onSend={handleSend}
                        onStop={onStop}
                        placeholder="What would you change?"
                        disabled={status.state === "processing" || isDisabled}
                        customButtomRight={customButtomRight}
                        customButtomLeft={customButtomLeft}
                        isProcessing={status.state === "processing"}
                    />
                </div>
                {isAdmin && <div className="text-xs text-gray-400 text-center opacity-0 hover:opacity-100 transition-opacity duration-200 absolute bottom-0 right-0">
                    <a onClick={copyAllMessagesToClipboard} className="hover:underline inline-flex items-center gap-1">
                        <Copy className="w-3 h-3" />
                        Copy
                    </a>
                </div>}
            </div>
        </div>
    );
}

export default Chat;