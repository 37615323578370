import React, { useState } from 'react';
import { Globe, Copy, Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../../../context/AppContext';
import UserAppAPI from '../../../api/UserAppAPI';
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { useToast } from "@/hooks/use-toast";
import { Switch } from "@/components/ui/switch";
import IntegrationWithOtherApps from "@/components/user-app/IntegrationWithOtherApps";

export default function AppSettings() {
    const { app } = useApp();
    const navigate = useNavigate();
    const { toast } = useToast();
    const [isLoadingPublish, setIsLoadingPublish] = useState(false);
    const [isCloningApp, setIsCloningApp] = useState(false);
    const [isAdvancedVisible, setIsAdvancedVisible] = useState(false);

    const handleAppPublish = async () => {
        try {
            setIsLoadingPublish(true);
            if (app.app_publish_info === null) {
                await UserAppAPI.publishApp(app.id);
                toast({
                    title: "Success",
                    description: "App published successfully. It will be listed in Base44's app catalog and might appear on trending apps."
                });
            } else {
                await UserAppAPI.unpublishApp(app.id);
                toast({
                    title: "Success",
                    description: "App unpublished successfully"
                });
            }
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update app",
                variant: "destructive"
            });
        } finally {
            setIsLoadingPublish(false);
        }
    };

    const handleCloneApp = async () => {
        try {
            setIsCloningApp(true);
            const newApp = await UserAppAPI.cloneApp(app.id);
            toast({
                title: "Success",
                description: "App cloned successfully"
            });
            navigate(`/apps/${newApp.id}/editor/preview`);
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to clone app",
                variant: "destructive"
            });
        } finally {
            setIsCloningApp(false);
        }
    };

    const showDeleteConfirm = () => {
        if (window.confirm('Are you sure you want to delete this app? This action cannot be undone.')) {
            handleDeleteApp();
        }
    };

    const handleDeleteApp = async () => {
        try {
            await UserAppAPI.delete(app.id);
            toast({
                title: "Success",
                description: "App deleted successfully"
            });
            navigate('/');
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to delete app",
                variant: "destructive"
            });
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-2 space-y-8">
            <h2 className="text-3xl font-bold mb-6">App Settings</h2>

            <div className="p-2 space-y-6">
                {/* <div className="flex justify-between items-center border-b pb-4">
                    <div>
                        <h3 className="text-lg font-semibold">App Publication</h3>
                        <p className="text-sm text-gray-500">List your app in Base44's catalog and trending apps.</p>
                    </div>
                    <Switch 
                        checked={app.app_publish_info !== null}
                        onChange={handleAppPublish}
                        disabled={isLoadingPublish}
                    />
                </div> */}

                <div className="flex justify-between items-center border-b pb-4">
                    <h3 className="text-lg font-semibold">Clone App</h3>
                    <Button 
                        onClick={handleCloneApp} 
                        disabled={isCloningApp}
                        className="flex items-center"
                    >
                        <Copy className="mr-2 h-4 w-4" />
                        {isCloningApp ? "Creating Copy..." : "Create Copy"}
                    </Button>
                </div>

                <div className="flex justify-between items-center border-b pb-4">
                    <h3 className="text-lg font-semibold text-red-600">Danger Zone</h3>
                    <Button 
                        onClick={showDeleteConfirm} 
                        variant="destructive"
                        className="flex items-center"
                    >
                        <Trash2 className="mr-2 h-4 w-4" />
                        Delete App
                    </Button>
                </div>

                <div className="flex justify-between items-center">
                    <h3 className="text-lg font-semibold">Advanced Settings</h3>
                    <Button 
                        onClick={() => setIsAdvancedVisible(!isAdvancedVisible)}
                        className="flex items-center"
                    >
                        {isAdvancedVisible ? "Hide" : "Show"}
                    </Button>
                </div>

                {isAdvancedVisible && (
                    <div className="mt-4 border rounded-lg bg-gray-50">
                        <IntegrationWithOtherApps />
                    </div>
                )}
            </div>
        </div>
    );
}
