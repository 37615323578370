import { BaseAPIProvider } from './base'

export class BillingAPI extends BaseAPIProvider {
    getBaseURL() {
        return '/billing'
    }

    createCheckoutSession(priceId) {
        return this.axios.post(`/create-checkout-session/${priceId}`)
    }
}

export default new BillingAPI()