import React, { useState } from 'react';
import CodeEditor from '../../../components/CodeEditor';
import { useAuth } from "../../../context/AuthContext";
import { useApp } from '../../../context/AppContext';
import { API_BASE_URL } from '../../../config';
import { Copy } from 'lucide-react';

export function AppAPI() {
    const { user } = useAuth();
    const { app } = useApp();
    const [isCopied, setIsCopied] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState(Object.keys(app.entities)[0] || 'EntityName');
    const [activeTab, setActiveTab] = useState('javascript');

    const handleCopyClick = (code) => {
        navigator.clipboard.writeText(code);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
    };

    const entityProperties = app.entities[selectedEntity]?.properties || {};
    const filterableFields = Object.keys(entityProperties).join(', ');

    const exampleCodeJS = `// JavaScript Example: Reading Entities
// Filterable fields: ${filterableFields}
async function fetch${selectedEntity}Entities() {
    const response = await fetch(\`${API_BASE_URL}/apps/${app.id}/entities/${selectedEntity}\`, {
        headers: {
            'api_key': '${user.api_key}', // or use await User.me() to get the API key
            'Content-Type': 'application/json'
        }
    });
    const data = await response.json();
    console.log(data);
}

// JavaScript Example: Updating an Entity
// Filterable fields: ${filterableFields}
async function update${selectedEntity}Entity(entityId, updateData) {
    const response = await fetch(\`${API_BASE_URL}/apps/${app.id}/entities/${selectedEntity}/\${entityId}\`, {
        method: 'PUT',
        headers: {
            'api_key': '${user.api_key}', // or use await User.me() to get the API key
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(updateData)
    });
    const data = await response.json();
    console.log(data);
}`;

    const exampleCodePython = `# Python Example: Reading Entities
# Filterable fields: ${filterableFields}
import requests

def make_api_request(api_path, method='GET', data=None):
    url = f'{API_BASE_URL}/{api_path}'
    headers = {
        'api_key': '${user.api_key}',
        'Content-Type': 'application/json'
    }
    if method.upper() == 'GET':
        response = requests.request(method, url, headers=headers, params=data)
    else:
        response = requests.request(method, url, headers=headers, json=data)
    response.raise_for_status()
    return response.json()

entities = make_api_request(f'apps/${app.id}/entities/${selectedEntity}')
print(entities)

# Python Example: Updating an Entity
# Filterable fields: ${filterableFields}
def update_entity(entity_id, update_data):
    response = requests.put(
        f'{API_BASE_URL}/apps/${app.id}/entities/${selectedEntity}/{entity_id}',
        headers={
            'api_key': '${user.api_key}',
            'Content-Type': 'application/json'
        },
        json=update_data
    )
    response.raise_for_status()
    return response.json()`;

    return (
        <div className="p-8">
            <h2 className="text-2xl font-bold mb-4">
                🚀 API Integration Made Easy
            </h2>
            <h3 className="text-xl mb-2">
                Example: API Operations
            </h3>
            <p className="mb-4">
                Select an entity to generate code examples for reading and updating:
            </p>
            <select
                value={selectedEntity}
                onChange={(e) => setSelectedEntity(e.target.value)}
                className="mb-4 p-2 border rounded"
            >
                {Object.keys(app.entities).map(entity => (
                    <option key={entity} value={entity}>{entity}</option>
                ))}
            </select>
            <div className="mb-4">
                <button onClick={() => setActiveTab('javascript')} className={`mr-2 ${activeTab === 'javascript' ? 'font-bold' : ''}`}>JavaScript</button>
                <button onClick={() => setActiveTab('python')} className={`${activeTab === 'python' ? 'font-bold' : ''}`}>Python</button>
            </div>
            <div className="relative">
                <CodeEditor
                    value={activeTab === 'javascript' ? exampleCodeJS : exampleCodePython}
                    language={activeTab}
                    readOnly={true}
                    height="600px"
                />
                <button
                    onClick={() => handleCopyClick(activeTab === 'javascript' ? exampleCodeJS : exampleCodePython)}
                    className="btn btn-sm btn-outline absolute top-2 right-2"
                >
                    <Copy className="w-4 h-4 mr-2" />
                    {isCopied ? 'Copied!' : 'Copy'}
                </button>
            </div>
        </div>
    );
}

export default AppAPI;