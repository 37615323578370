import { BaseAPIModelProvider } from './base'

class UsageLogsAPI extends BaseAPIModelProvider {
    getBaseURL() {
        return '/usage-logs'
    }

    async getStats() {
        return await this.axios.get('/stats')
    }

    async getCurrentUsage() {
        return await this.axios.get('/current-usage')
    }

    async getAdminStats() {
        return await this.axios.get('/admin-stats')
    }
}


export default new UsageLogsAPI();
