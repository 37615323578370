import React, { useState, useEffect } from 'react';
import UserAppAPI from "../../api/UserAppAPI";
import { API_BASE_URL } from '../../config';
import { Copy } from 'lucide-react';
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { Select, SelectTrigger, SelectContent, SelectItem } from "@/components/ui/select";
import { useApp } from '../../context/AppContext';

const IntegrationWithOtherApps = () => {
    const [apps, setApps] = useState([]);
    const [selectedApp, setSelectedApp] = useState(null);
    const [selectedEntity, setSelectedEntity] = useState('');
    const [isCopied, setIsCopied] = useState(false);
    const { app: currentApp } = useApp();

    useEffect(() => {
        const fetchUserApps = async () => {
            try {
                const userApps = await UserAppAPI.list('-updated_date', 9, 0, ['id', 'logo_url', 'name', 'entities'].join(','));
                const filteredApps = userApps.filter(app => app.id !== currentApp.id);
                setApps(filteredApps);
                if (filteredApps.length > 0) {
                    setSelectedApp(filteredApps[0]);
                    setSelectedEntity(Object.keys(filteredApps[0].entities)[0] || '');
                }
            } catch (error) {
                console.error('Failed to fetch user apps:', error);
            }
        };

        fetchUserApps();
    }, [currentApp.id]);

    const handleCopyClick = (prompt) => {
        navigator.clipboard.writeText(prompt);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
    };

    const entityProperties = selectedApp?.entities[selectedEntity]?.properties || {};
    const integrationPrompt = `Please integrate the ${selectedEntity} entity from another app, called ${selectedApp?.name}.
Here's the entity schema: ${JSON.stringify(entityProperties, null, 2)}
Here's how you integrate it - JavaScript code examples:
1. Reading Entities:
async function fetch${selectedEntity}Entities(filters = null) {
    // filters can be any one of the properties in the entity schema, or created_by (which is the user's email)
    const url = filters 
        ? \`${API_BASE_URL}/apps/${selectedApp?.id}/entities/${selectedEntity}?q=\${JSON.stringify(filters)}\`
        : \`${API_BASE_URL}/apps/${selectedApp?.id}/entities/${selectedEntity}\`;
    const response = await fetch(url, {
        headers: {
            'api_key': await User.me().api_key,
            'Content-Type': 'application/json'
        }
    });
    const data = await response.json();
    console.log(data);
}

2. Updating an Entity:
async function update${selectedEntity}Entity(entityId, updateData) {
    const response = await fetch(\`${API_BASE_URL}/apps/${selectedApp?.id}/entities/${selectedEntity}/\${entityId}\`, {
        method: 'PUT',
        headers: {
            'api_key': await User.me().api_key,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(updateData)
    });
    const data = await response.json();
    console.log(data);
}`;

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">
                Integration with Other Apps
            </h2>
            <p className="mb-4">
                Select an app and an entity to generate a prompt for integrating between apps:
            </p>
            <Select
                value={selectedApp?.id || ''}
                onValueChange={(value) => {
                    const app = apps.find(app => app.id === value);
                    setSelectedApp(app);
                    setSelectedEntity(Object.keys(app.entities)[0] || '');
                }}
            >
                <SelectTrigger className="mb-4 p-4 border rounded">
                    {selectedApp ? (
                        <div className="flex items-center">
                            <Avatar className="inline-block mr-2">
                                <AvatarImage src={selectedApp.logo_url} alt={selectedApp.name} />
                            </Avatar>
                            {selectedApp.name}
                        </div>
                    ) : (
                        "Select an app"
                    )}
                </SelectTrigger>
                <SelectContent>
                    {apps.map(app => (
                        <SelectItem key={app.id} value={app.id}>
                            <div className="flex items-center">
                                <Avatar className="inline-block mr-2">
                                    <AvatarImage src={app.logo_url} alt={app.name} />
                                </Avatar>
                                {app.name}
                            </div>
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
            {selectedApp && (
                <>
                    <Select
                        value={selectedEntity}
                        onValueChange={(value) => setSelectedEntity(value)}
                    >
                        <SelectTrigger className="mb-4 p-2 border rounded">
                            {selectedEntity || "Select an entity"}
                        </SelectTrigger>
                        <SelectContent>
                            {Object.keys(selectedApp.entities).map(entity => (
                                <SelectItem key={entity} value={entity}>{entity}</SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                    <div className="relative">
                        <p className="mb-4">
                            Copy this prompt to integrate the {selectedEntity} entity into this app:
                        </p>
                        <pre className="bg-gray-100 p-4 rounded mb-4 overflow-x-auto">
                            {integrationPrompt}
                        </pre>
                        <button
                            onClick={() => handleCopyClick(integrationPrompt)}
                            className="btn btn-sm btn-outline absolute top-2 right-2"
                        >
                            <Copy className="w-4 h-4 mr-2" />
                            {isCopied ? 'Copied!' : 'Copy'}
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default IntegrationWithOtherApps;
