import { useState, useEffect } from 'react'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { AlertCircle, CheckCircle2, RefreshCw, HelpCircle, Trash2, Plus, Globe } from 'lucide-react'
import AppDomainsAPI from '@/api/AppDomainsAPI'
import { useAuth } from '../../../context/AuthContext';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

const AppDomainInstructions = () => {
  return (
    <Card>
      <CardContent className="p-4">
        <div className="flex items-start gap-3">
          <HelpCircle className="h-5 w-5 text-blue-500 shrink-0 mt-0.5" />
          <div className="space-y-2 text-sm">
            <p className="font-medium"><b>After adding a domain</b>, connect it via your DNS provider:</p>
            <ol className="list-decimal list-inside space-y-1 text-gray-600">
              <li>Add CNAME record: <code className="bg-gray-100 px-1.5 py-0.5 rounded">www → base44.onrender.com</code></li>
              <li>Add ANAME/ALIAS record: <code className="bg-gray-100 px-1.5 py-0.5 rounded">empty or @ → base44.onrender.com</code></li>
              <li>If your DNS provider doesn't support ANAME/ALIAS, use A record: <code className="bg-gray-100 px-1.5 py-0.5 rounded">@ → 216.24.57.1</code></li>
            </ol>
            <p className="text-gray-600">Note: After adding DNS records, it may take some time for changes to fully propagate across the internet. During this time, the domain may not work properly.</p>
            <p className="text-xs text-gray-500">
              Need help? Contact <a href="mailto:app@base44.com" className="text-blue-500 hover:underline">app@base44.com</a>
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

const DomainRecord = ({domain, onUpdate}) => {
    const { toast } = useToast()
    const [isVerifying, setIsVerifying] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [isChecking, setIsChecking] = useState(false)

    const handleVerify = async () => {
        setIsVerifying(true)
        try {
            await AppDomainsAPI.verify(domain.id)
            toast({
                title: "Verification initiated", 
                description: "Please check back in a few minutes",
                duration: 3000
            })
            onUpdate()
        } catch (error) {
            toast({
                variant: "destructive",
                title: "Error verifying domain",
                description: error.response?.data?.detail || "Something went wrong",
                duration: 3000
            })
        }
        setIsVerifying(false)
    }

    const handleDelete = async () => {
        setIsDeleting(true)
        try {
            await AppDomainsAPI.delete(domain.id)
            toast({
                title: "Domain deleted",
                duration: 3000
            })
            onUpdate()
        } catch (error) {
            toast({
                variant: "destructive",
                title: "Error deleting domain",
                description: error.response?.data?.detail || "Something went wrong",
                duration: 3000
            })
        }
        setIsDeleting(false)
    }

    const checkStatus = async () => {
        setIsChecking(true)
        try {
            const response = await AppDomainsAPI.refreshStatus(domain.id)
            onUpdate()
        } catch (error) {
            toast({
                variant: "destructive",
                title: "Error checking status",
                description: error.response?.data?.detail || "Something went wrong",
                duration: 3000
            })
        }
        setIsChecking(false)
    }

    console.log("DOMAIN", domain);

    return (
        <Card>
            <CardContent className="p-4">
                <div className="flex flex-col space-y-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-3">
                            {domain.last_status_payload?.verificationStatus === 'verified' ? (
                                <CheckCircle2 className="h-5 w-5 text-green-500" />
                            ) : (
                                <AlertCircle className="h-5 w-5 text-yellow-500" />
                            )}
                            <div>
                                <p className="font-medium">{domain.domain}</p>
                                {domain.last_status_payload && (    
                                    <div className="space-y-1">
                                        <p className="text-sm text-gray-500">
                                            {domain.last_status_payload?.verificationStatus || 'Pending refresh'}
                                        </p>
                                        {domain.last_status_check && (
                                            <p className="text-xs text-gray-400">
                                                Last checked: {moment.utc(domain.last_status_check).fromNow()}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <Button
                            variant="ghost"
                            size="sm"
                            onClick={handleDelete}
                            className="text-red-500 hover:text-red-600 hover:bg-red-50"
                            disabled={isDeleting}
                        >
                            <Trash2 className={`h-4 w-4 ${isDeleting ? 'animate-spin' : ''}`} />
                        </Button>
                    </div>
                    <div className="flex items-center gap-2 justify-end">
                        <Button
                            variant="ghost"
                            size="sm"
                            onClick={checkStatus}
                            className="text-gray-500"
                            disabled={isChecking}
                        >
                            <RefreshCw className={`h-4 w-4 mr-2 ${isChecking ? 'animate-spin' : ''}`} />
                            Check Status
                        </Button>
                        <Button
                            variant="outline"
                            size="sm"
                            onClick={handleVerify}
                            disabled={isVerifying}
                        >
                            {isVerifying ? (
                                <>
                                    <RefreshCw className="h-4 w-4 mr-2 animate-spin" />
                                    Verifying...
                                </>
                            ) : (
                                'Verify Domain'
                            )}
                        </Button>
                        
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

const AddDomainDialog = ({ onAdd, loading }) => {
    const [newDomain, setNewDomain] = useState('')
    const [open, setOpen] = useState(false)
    
    const handleAdd = async () => {
        await onAdd(newDomain)
        setNewDomain('')
        setOpen(false)
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button>
                    <Plus className="h-4 w-4 mr-2" />
                    Add Domain
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Add New Domain</DialogTitle>
                    <DialogDescription>
                        Enter your domain name below. Make sure it follows the format: app.example.com
                    </DialogDescription>
                </DialogHeader>
                <div className="space-y-4 pt-4">
                    <Input
                        placeholder="Enter domain (e.g. app.example.com)"
                        value={newDomain}
                        onChange={(e) => setNewDomain(e.target.value)}
                    />
                    <div className="flex justify-end">
                        <Button 
                            onClick={handleAdd}
                            disabled={loading || !newDomain}
                        >
                            {loading ? (
                                <>
                                    <RefreshCw className="h-4 w-4 mr-2 animate-spin" />
                                    Adding...
                                </>
                            ) : (
                                'Add Domain'
                            )}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default function AppDomains({ appId }) {
    const {user} = useAuth();
    const [domains, setDomains] = useState([])
    const [loading, setLoading] = useState(false)
    const { toast } = useToast()
    const navigate = useNavigate()

    const isPaidTier = ['builder', 'pro', 'enterprise'].includes(user?.subscription_tier)

    const fetchDomains = async () => {
        try {
            const response = await AppDomainsAPI.filter({ app_id: appId })
            setDomains(response)
        } catch (error) {
            toast({
                variant: "destructive",
                title: "Error fetching domains",
                description: error.response?.data?.detail || "Something went wrong",
                duration: 3000
            })
        }
    }

    useEffect(() => {
        if (isPaidTier) {
            fetchDomains()
        }
    }, [appId, isPaidTier])

    const handleCreateDomain = async (domain) => {
        if (!domain) return;
        setLoading(true);
        try {
            await AppDomainsAPI.create({
                domain,
                app_id: appId
            })
            await fetchDomains()
            toast({
                title: "Domain added successfully",
                description: "Please verify your domain to complete setup",
                duration: 3000
            })
        } catch (error) {
            toast({
                variant: "destructive",
                title: "Error creating domain",
                description: error.response?.data?.detail || "Something went wrong",
                duration: 3000
            })
        }
        setLoading(false)
    }

    if (!isPaidTier) {
        return (
            <div className="flex flex-col items-center justify-center h-[calc(100vh-200px)] p-6">
                <Card className="w-full max-w-lg">
                    <CardContent className="p-6 text-center">
                        <Globe className="h-12 w-12 text-blue-500 mx-auto mb-4" />
                        <h3 className="text-xl font-semibold mb-2">Want to use your own domain?</h3>
                        <p className="text-gray-600 mb-6">
                            Custom domains are a premium feature available on our Builder plan and above. Upgrade to connect your own domain to this app.
                        </p>
                        <Button 
                            onClick={() => navigate('/billing')}
                            className="w-full"
                        >
                            View Plans
                        </Button>
                    </CardContent>
                </Card>
            </div>
        )
    }

    return (
        <div className="space-y-6 p-6">
            <div>
                <div className="flex items-center justify-between mb-6">
                    <h2 className="text-2xl font-bold">Custom Domains</h2>
                    <AddDomainDialog onAdd={handleCreateDomain} loading={loading} />
                </div>

                <div className="grid gap-6">
                    {domains.length > 0 && (
                        <div className="space-y-4">
                            {domains.map((domain) => (
                                <DomainRecord 
                                    key={domain.id} 
                                    domain={domain}
                                    onUpdate={fetchDomains}
                                />
                            ))}
                        </div>
                    )}

                    <AppDomainInstructions />
                </div>
            </div>
        </div>
    )
}
