import React, { useState, useEffect } from 'react';
import { ChevronLeft } from 'lucide-react';
import { starters } from './starters';
import ChatTextArea from '../../components/chat/chat-input/AppChatInput';
import { Button } from "@/components/ui/button";

export default function AppConversationStarter ({ onStart, loading })  {
    const [appDescription, setAppDescription] = useState('');
    const [files, setFiles] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleStart = () => {
        const trimmedDescription = appDescription.trim();
        if (trimmedDescription) {
            onStart({
                text: trimmedDescription,
                files: files
            });
            setAppDescription('');
            setFiles([]);
        }
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    const handleSubPromptClick = (description) => {
        setAppDescription(description);
    };

    const handleBackToCategories = () => {
        setSelectedCategory(null);
    };

    return (
        <div>
            <div className="relative">
                <ChatTextArea
                    value={appDescription}
                    files={files}
                    onSend={handleStart}
                    placeholder="Describe the app you want to create..."
                    disabled={loading}
                    isProcessing={loading}
                    onValueChange={setAppDescription}
                    onFilesChange={setFiles}
                />
                
            </div>
            <div className="text-sm text-muted-foreground mt-4">Some ideas to get started:</div>
            <div className="mt-4">
                {selectedCategory ? (
                    <div>
                        <Button
                            onClick={handleBackToCategories}
                            variant="ghost"
                            size="sm"
                            className="mb-2"
                        >
                            <ChevronLeft className="h-4 w-4 mr-2" />
                            Back to categories
                        </Button>
                        <h3 className="text-lg font-semibold mb-2">{selectedCategory} Prompts</h3>
                        <div className="flex flex-wrap gap-2">
                            {starters[selectedCategory].map((subPrompt, index) => (
                                <Button
                                    key={index}
                                    variant="secondary"
                                    size="sm"
                                    onClick={() => handleSubPromptClick(subPrompt.description)}
                                >
                                    {subPrompt.title}
                                </Button>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-wrap gap-2">
                        {Object.keys(starters).map((category, index) => (
                            <Button
                                key={index}
                                variant="secondary"
                                size="sm"
                                onClick={() => handleCategoryClick(category)}
                            >
                                {category}
                            </Button>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};