import { BaseAPIModelProvider } from './base'

class UserAppAPI extends BaseAPIModelProvider {
    getBaseURL() {
        return '/apps'
    }

    createFromMessage(msg) {
        return this.axios.post('/chat/create-from-message', msg)
    }

    addMessage(appId, message) {
        return this.axios.post(`/${appId}/chat/message`, message)
    }

    editMessage(appId, messageId, message) {
        return this.axios.put(`/${appId}/chat/message/${messageId}`, message)
    }

    undoMessage(appId, messageId) {
        return this.axios.post(`/${appId}/chat/message/${messageId}/undo`)
    }

    redoMessage(appId, messageId) {
        return this.axios.post(`/${appId}/chat/message/${messageId}/redo`)
    }

    codePage(appId, pageId) {
        return this.axios.post(`/${appId}/pages/${pageId}/generate-code`)
    }

    editPageCode(appId, pageId, changes, image) {
        return this.axios.post(`/${appId}/pages/${pageId}/edit-code`, { changes, image_as_url: image })
    }

    compilePageCode(appId, pageId) {
        return this.axios.get(`/${appId}/pages/${pageId}/compile-code`)
    }

    manualEditPageCode(appId, pageId, code) {
        return this.axios.post(`/${appId}/pages/${pageId}/manual-edit-code`, { code })
    }

    loadCheckpoint(appId, checkpointId) {
        return this.axios.post(`/${appId}/history/${checkpointId}/load-checkpoint`)
    }

    runScript(appId, scriptId, inputs) {
        return this.axios.post(`/${appId}/scheduled-actions/${scriptId}/run`, inputs)
    }

    listScriptRuns(appId, scriptId) {
        return this.axios.get(`/${appId}/scheduled-actions/${scriptId}/runs`)
    }

    regenerateScript(appId, scriptId) {
        return this.axios.post(`/${appId}/scheduled-actions/${scriptId}/generate-code`)
    }

    aiEditScriptCode(appId, scriptId, changes) {
        return this.axios.post(`/${appId}/scheduled-actions/${scriptId}/edit-code`, { changes })
    }

    manualEditScriptCode(appId, scriptId, code) {
        return this.axios.post(`/${appId}/scheduled-actions/${scriptId}/manual-edit-code`, { code })
    }

    registerUser(appId) {
        return this.axios.post(`/${appId}/users/register`)
    }

    inviteUser(appId, userEmail, role) {
        return this.axios.post(`/${appId}/users/invite-user`, { user_email: userEmail, role })
    }

    updateUserRole(appId, userEmail, role) {
        return this.axios.post(`/${appId}/users/update-user-role`, { user_email: userEmail, role })
    }

    getMyUserInfo(appId) {
        return this.axios.get(`/${appId}/entities/User/me`)
    }

    cloneApp(appId) {
        return this.axios.post(`/${appId}/clone`)
    }

    updateLogo(appId, payload) {
        return this.axios.post(`/${appId}/update-logo`, payload)
    }

    publishApp(appId) {
        return this.axios.post(`/${appId}/publish`)
    }

    unpublishApp(appId) {
        return this.axios.post(`/${appId}/unpublish`)
    }

    stopChat(appId) {
        return this.axios.post(`/${appId}/chat/stop`)
    }

    getPublicAppInfoFromSlug(slug) {
        return this.axios.get(`/public/prod/by-slug/${slug}`)
    }

    getPublicAppInfoFromId(appId) {
        return this.axios.get(`/public/prod/by-id/${appId}`)
    }

    getDomainAppId(domain) {
        return this.axios.get(`/public/prod/domain/${domain}`)
    }

    getLoginInfoBySlug(slug) {
        return this.axios.get(`/public/login-info/by-slug/${slug}`)
    }

    getLoginInfoById(appId) {
        return this.axios.get(`/public/login-info/by-id/${appId}`)
    }

    getSuggestions(appId) {
        return this.axios.post(`/${appId}/chat/generate-suggestions`)
    }
}


export default new UserAppAPI();
