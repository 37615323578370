import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from '../../context/AuthContext';
import AppDemo from './demo/AppDemo';
import UserAppAPI from '../../api/UserAppAPI';
import Login from '../../pages/login/Login';


export default function CustomDomainApp({}) {
  const [appId, setAppId] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const domain = window.location.hostname;

  useEffect(() => {
    UserAppAPI.getDomainAppId(domain)
      .then((response) => {
        setAppId(response);
        window.appId = response;
        setIsLoading(false);
      })
      .catch((err) => {
        setError(true);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <div className="w-12 h-12 border-4 border-gray-200 border-t-black rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen p-4">
        <div className="text-center">
          <h1 className="text-2xl font-bold mb-4">Domain Not Recognized</h1>
          <p className="mb-2">This domain is not associated with any Base44 app.</p>
          <p>Please contact support at <a href="mailto:app@base44.com" className="text-blue-600 hover:underline">app@base44.com</a> for assistance.</p>
        </div>
      </div>
    );
  }
    
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/:pageName?" element={<AppDemo appId={appId} />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}