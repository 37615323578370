import { useState, useEffect } from 'react';
import UserAppAPI from '../../api/UserAppAPI';
import { Card, CardContent } from "@/components/ui/card";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { Loader2 } from "lucide-react";

export default function AppLogin({appId, googleLoginButton}) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [appInfo, setAppInfo] = useState(null);

  useEffect(() => {
    const fetchAppInfo = async () => {
      try {
        const app = await UserAppAPI.getLoginInfoById(appId);
        setAppInfo(app);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchAppInfo();
  }, [appId]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-50 via-white to-purple-50">
        <div className="p-8 rounded-full bg-white/30 backdrop-blur-sm">
          <Loader2 className="h-10 w-10 animate-spin text-indigo-600" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-rose-50 via-white to-orange-50">
        <div className="p-8 text-lg font-medium text-rose-600 bg-white/80 backdrop-blur-sm rounded-lg shadow-sm">
          An error occurred while loading the app
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-50 via-white to-purple-50 p-4">
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]"></div>
      <div className="absolute inset-0 bg-white/30 backdrop-blur-[1px]"></div>
      
      <Card className="relative max-w-md w-full overflow-hidden border-0 shadow-[0_0_1px_rgba(0,0,0,0.1),0_8px_32px_-8px_rgba(0,0,0,0.1)] bg-white/70 backdrop-blur-xl">
        <div className="absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500"></div>
        <CardContent className="pt-16 pb-14 px-12 flex flex-col items-center text-center space-y-10">
          {appInfo?.logo_url && (
            <div className="relative">
              <div className="absolute -inset-4 bg-gradient-to-br from-indigo-100 to-purple-100 rounded-full blur-2xl opacity-50 group-hover:opacity-75 transition-opacity"></div>
              <Avatar className="relative h-32 w-32 shadow-xl ring-4 ring-white/90 transition-all duration-300 hover:scale-105">
                <AvatarImage src={appInfo.logo_url} alt={`${appInfo.name} logo`} className="object-cover" />
                <AvatarFallback className="bg-gradient-to-br from-indigo-50 to-purple-50 text-3xl font-semibold text-indigo-600">
                  {appInfo.name?.[0]}
                </AvatarFallback>
              </Avatar>
            </div>
          )}
          
          <div className="space-y-4">
            <h1 className="text-4xl font-bold bg-gradient-to-br from-gray-900 to-gray-600 bg-clip-text text-transparent">
              Welcome to {appInfo?.name}
            </h1>

            <p className="text-gray-500 text-lg font-medium">
              Sign in to continue your journey
            </p>
          </div>

          <div className="w-full">
            {googleLoginButton}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};