import _ from 'lodash';
import Collapsable from '../Collapsable';
import { Wrench } from 'lucide-react';

export function ToolCall({ toolCall }) {
    const formatArguments = (args) => {
        if (typeof args === 'object' && args !== null) {
            return Object.entries(args).map(([key, value]) => {
                const stringValue = typeof value === 'object' ? JSON.stringify(value) : value.toString();
                return (
                    <div key={key} className="mb-1 text-sm">
                        <span className="font-semibold">{key}:</span>{' '}
                        {stringValue.split('\n').map((line, i) => (
                            <span key={i}>
                                {i > 0 && <br />}
                                {line}
                            </span>
                        ))}
                    </div>
                );
            });
        }
        return args;
    };

    return (
        <Collapsable
            title={
                <div className="font-small flex items-center">
                    <Wrench className="w-4 h-4 mr-2" />
                    <span className="font-semibold">Action: {toolCall.name}</span>
                </div>
            }
        >
            <div className="ml-2 text-gray-700">
                {formatArguments(toolCall.arguments)}
            </div>
        </Collapsable>
    );
}

export function ToolResults({ toolResults }) {
    const displayResults = toolResults.display_results;
    if (_.isObject(displayResults)) {
        const {widget, data} = displayResults;
        if (widget === 'table') {
            return (
                <div className="overflow-x-auto">
                    <table className="table w-full bg-white">
                        <thead>
                            <tr>
                                {data.columns.map((column, index) => (
                                    <th key={index}>{column}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.rows.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {data.columns.map((column, colIndex) => (
                                        <td key={colIndex}>{row[column]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        }
    }


    return (
        <Collapsable
            title={
                <div className="font-semibold">
                    Action Results
                </div>
            }
        >
            <div className="text-gray-700">
                <p>{toolResults.results}</p>
                <div className="text-xs text-gray-500 mt-1">
                    (This is the result of a tool execution, not the AI's response)
                </div>
            </div>
        </Collapsable>
    );
}