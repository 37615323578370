


export function toCamelCase(str) { 
    // transform "my-component" to "MyComponent"
    return str
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join('');
}


export function addLineNumbersToJSX(filename, code) {
    // Split the code into lines while preserving empty lines
    const lines = code.split('\n');
    
    // Regular expression to match JSX element openings
    // Matches elements like <Button, <div, <Card, etc.
    const jsxElementRegex = /(<[A-Z][a-zA-Z]*|<[a-z]+)(\s|>|\/>)/g;
    
    // Process each line
    const processedLines = lines.map((line, index) => {
      const lineNumber = index + 1;
      
      // Skip lines that don't contain JSX elements
      if (!jsxElementRegex.test(line)) {
        return line;
      }
      
      // Reset the regex lastIndex for multiple uses
      jsxElementRegex.lastIndex = 0;
      
      // Replace each JSX element opening with the same element plus line number
      return line.replace(jsxElementRegex, (match, element, ending) => {
        // If the ending is "/>" or ">", insert before it
        if (ending.includes('>')) {
          const beforeClosing = ending.indexOf('>');
          return `${element} data-filename="${filename}" data-linenumber="${lineNumber}"${ending}`;
        }
        // If it's just a space, add after the space
        return `${element}${ending}data-filename="${filename}" data-linenumber="${lineNumber}" `;
      });
    });
    
    // Rejoin the lines
    return processedLines.join('\n');
  }
  