import { BaseAPIProvider } from './base'
import createClient from './axiosClient'
class AppLogsAPI extends BaseAPIProvider {
    getBaseURL() {
        return '/app-logs'
    }

    fetchLogs(appId, params = {}) {
        return this.axios.get(`/${appId}`, { params })
    }

    logUserInApp(appId, pageName) {
        return this.axios.post(`/${appId}/log-user-in-app/${pageName}`)
    }

    getClient() {
        // fail silently
		return createClient(this.getBaseURL(), {}, true)
	}

    getStats(appId, params = {}) {
        return this.axios.get(`/${appId}/stats`, { params })
    }

    getTrendingApps() {
        const client = createClient("/apps/public", {}, true)
        return client.get('/trending')
    }
}


export default new AppLogsAPI();
