import { useState } from 'react';
import moment from 'moment';
import UserAvatar from '../UserAvatar';
import ChatTextArea from './chat-input/AppChatInput';
import ChatMarkdown from './ChatMarkdown';
import { ToolCall, ToolResults } from './ToolsMsg';
import AutomatedFixMessage from './AutomatedFixMessage';
import { elementTypeToIcon } from '../user-app/chat/SelectElement';
import { Code } from 'lucide-react';

export default function ChatMessage({ item, editMessage, undoMessage }) {
    const [isEditing, setIsEditing] = useState(false);
    const [editingValue, setEditingValue] = useState(item.content);

    const onEditChange = (e) => {
        setEditingValue(e.target.value);
        item.content = e.target.value;
    }

    const onEditSave = () => {
        editMessage(item.id, {...item, content: editingValue});
        setIsEditing(false);
    }

    const onEditKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onEditSave();
        }
    }

    const onUndo = () => {
        if (window.confirm("Are you sure you want to undo this message? This will load the state of the app before this message.")) {
            undoMessage(item.id);
        }
    }
    
    if (isEditing) {
        return <div className="flex flex-col space-y-2 p-2">
            <ChatTextArea
                value={item.content}
                onChange={onEditChange}
                onKeyDown={onEditKeyDown}
                placeholder="Edit your message..."
            />
            <button 
                onClick={onEditSave}
                className="btn btn-primary btn-sm self-end"
            >
                Edit
            </button>
        </div>
    }

    const isAutomatedFix = item.additional_message_params?.automated_fix;
    if (isAutomatedFix) {
        return <div className="mb-6 relative">
            <AutomatedFixMessage message={item} />
        </div>
    }

    return (
        <div className='mb-6 relative'>
            <div className="text-sm p-4 bg-gray-100 mx-auto mb-6 rounded-lg flex items-start">
                {item.role === 'user' && (
                    <div className="me-2 mt-1"><UserAvatar userEmail={item.metadata?.created_by_email} userFullName={item.metadata?.created_by_full_name} /></div>
                )}
                <div className="flex-1 pt-2">
                    {item.role === 'tool' ? (
                        item.tool_results.map((toolResults, index) => <ToolResults key={index} toolResults={toolResults} />)
                    ) : (
                        <>
                            {item.custom_context && item.custom_context.map((context, index) => {
                                if (context.type === 'element' && context.data?.element_tag_name) {
                                    const elementType = context.data.element_tag_name.toLowerCase();
                                    const icon = elementTypeToIcon[elementType] || <Code className="h-3 w-3" />;
                                    return (
                                        <div key={index} className="mb-2 flex items-center gap-2 text-xs">
                                            <div className="flex items-center gap-1 px-2 py-1 bg-gray-900 text-white rounded-md">
                                                {icon}
                                                <span>{elementType}</span>
                                            </div>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                            {item.file_urls && item.file_urls.length > 0 && (
                                <div className="mb-4 flex flex-wrap gap-4">
                                    {item.file_urls.map((url, index) => (
                                        <div key={index} className="relative">
                                            {url.match(/\.(jpg|jpeg|png|gif)$/i) ? (
                                                <img 
                                                    src={url} 
                                                    alt="Attached content"
                                                    className="max-w-[300px] max-h-[300px] rounded-lg border border-gray-200 shadow-sm"
                                                />
                                            ) : (
                                                <div className="flex items-center gap-2 p-2 bg-white rounded-lg border border-gray-200">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-500">
                                                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"/>
                                                        <polyline points="14 2 14 8 20 8"/>
                                                    </svg>
                                                    <a href={url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                                                        Attached file
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                            <ChatMarkdown content={item.content} msgId={item.id} role={item.role} />
                            {item.tool_calls && item.tool_calls.map((toolCall, index) => <ToolCall key={index} toolCall={toolCall} />)}
                        </>
                    )}
                    {item.metadata?.created_date && (
                        <div className="text-xs text-gray-400 mt-2">
                            {moment.utc(item.metadata.created_date, moment.ISO_8601).fromNow()}
                        </div>
                    )}
                </div>
            </div>
            {item.role === "user" && <div className="cursor-pointer absolute bottom-[-10px] right-0 bg-white border border-gray-200 rounded-md p-1 text-xs text-gray-400">
                <a onClick={onUndo} className="">Revert this</a>
            </div>}
        </div>
    );
} 