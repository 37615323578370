import { useRef, useEffect } from "react";
import { useApp } from "../../../context/AppContext";
import { X, Code, Layout, Text, Heading, MousePointer, Pointer } from "lucide-react";

const elementTypeToIcon = {
    div: <Layout className="h-3 w-3" />,
    button: <MousePointer className="h-3 w-3" />,
    p: <Text className="h-3 w-3" />,
    h1: <Heading className="h-3 w-3" />,
    h2: <Heading className="h-3 w-3" />,
    h3: <Heading className="h-3 w-3" />,
    h4: <Heading className="h-3 w-3" />,
    h5: <Heading className="h-3 w-3" />,
};

export { elementTypeToIcon };

let enableVisualEdits = false;

export default function SelectElement() {
    const {isElementMode, setIsElementMode, selectedElement, setSelectedElement} = useApp();
    const hoverOverlayRef = useRef(null);
    const selectedOverlayRef = useRef(null);
    const currentHighlightedElementRef = useRef(null);
    const selectedElementRef = useRef(null);

    const createOverlay = () => {
        const overlay = document.createElement('div');
        overlay.style.position = 'fixed';
        overlay.style.pointerEvents = 'none';
        overlay.style.transition = 'all 0.1s ease-in-out';
        overlay.style.zIndex = '9999';
        return overlay;
    };

    const positionOverlay = (overlay, element) => {
        if (!element || !isElementMode || !enableVisualEdits) return;
        
        const iframe = document.getElementById('preview-iframe');
        if (!iframe) return;
        
        const iframeRect = iframe.getBoundingClientRect();
        const rect = element.getBoundingClientRect();
        
        overlay.style.top = `${rect.top + iframeRect.top}px`;
        overlay.style.left = `${rect.left + iframeRect.left}px`;
        overlay.style.width = `${rect.width}px`;
        overlay.style.height = `${rect.height}px`;
    };

    const _areElementsEqual = (element1, element2) => {
        if (!element1 || !element2) return false;
        return element1.dataset.linenumber === element2.dataset.linenumber && 
               element1.dataset.filename === element2.dataset.filename;
    };

    const handleMouseOver = (e) => {
        if (!isElementMode || !enableVisualEdits) return;
        
        const element = e.target.closest('[data-linenumber]');
        if (!element || _areElementsEqual(element, selectedElementRef.current)) {
            if (hoverOverlayRef.current) {
                hoverOverlayRef.current.style.opacity = '0';
            }
            return;
        }

        if (!hoverOverlayRef.current) {
            const overlay = createOverlay();
            overlay.style.border = '2px solid #6366F1';
            overlay.style.backgroundColor = 'rgba(99, 102, 241, 0.1)';
            document.body.appendChild(overlay);
            hoverOverlayRef.current = overlay;
        }

        currentHighlightedElementRef.current = element;
        hoverOverlayRef.current.style.opacity = '1';
        positionOverlay(hoverOverlayRef.current, element);
    };

    const handleMouseOut = () => {
        if (hoverOverlayRef.current) {
            hoverOverlayRef.current.style.opacity = '0';
        }
    };

    const handleElementClick = (e) => {
        if (!isElementMode || !enableVisualEdits) return;
        
        const element = e.target.closest('[data-linenumber]');
        if (!element) return;

        if (!selectedOverlayRef.current) {
            const overlay = createOverlay();
            overlay.style.border = '2px solid #111827';
            overlay.style.backgroundColor = 'rgba(17, 24, 39, 0.05)';
            document.body.appendChild(overlay);
            selectedOverlayRef.current = overlay;
        }

        selectedElementRef.current = element;
        setSelectedElement(element);
        selectedOverlayRef.current.style.opacity = '1';
        positionOverlay(selectedOverlayRef.current, element);
    };

    const highlightFirstElement = () => {
        if (!isElementMode || !enableVisualEdits) return;

        const iframe = document.getElementById('preview-iframe');
        if (!iframe) return;

        const firstElement = iframe.contentDocument.querySelector('[data-linenumber]');
        if (!firstElement) return;

        if (!hoverOverlayRef.current) {
            const overlay = createOverlay();
            overlay.style.border = '2px solid #6366F1';
            overlay.style.backgroundColor = 'rgba(99, 102, 241, 0.1)';
            document.body.appendChild(overlay);
            hoverOverlayRef.current = overlay;
        }

        currentHighlightedElementRef.current = firstElement;
        hoverOverlayRef.current.style.opacity = '1';
        positionOverlay(hoverOverlayRef.current, firstElement);
    };

    const toggleElementSelect = () => {
        const iframe = document.getElementById('preview-iframe');
        if (!iframe) return;

        if (!isElementMode) {
            iframe.contentDocument.removeEventListener('mouseover', handleMouseOver);
            iframe.contentDocument.removeEventListener('mouseout', handleMouseOut);
            iframe.contentDocument.removeEventListener('click', handleElementClick);
            iframe.contentDocument.body.style.cursor = 'default';
            
            if (hoverOverlayRef.current) {
                hoverOverlayRef.current.remove();
                hoverOverlayRef.current = null;
            }
            if (selectedOverlayRef.current) {
                selectedOverlayRef.current.remove();
                selectedOverlayRef.current = null;
            }
            currentHighlightedElementRef.current = null;
            selectedElementRef.current = null;
        } else {
            iframe.contentDocument.addEventListener('mouseover', handleMouseOver);
            iframe.contentDocument.addEventListener('mouseout', handleMouseOut);
            iframe.contentDocument.addEventListener('click', handleElementClick);
            iframe.contentDocument.body.style.cursor = 'crosshair';
            
            // Highlight first element when entering element mode
            highlightFirstElement();
        }
    };

    useEffect(() => {
        enableVisualEdits = isElementMode;
        toggleElementSelect();
        return () => {
            if (hoverOverlayRef.current) hoverOverlayRef.current.remove();
            if (selectedOverlayRef.current) selectedOverlayRef.current.remove();
            currentHighlightedElementRef.current = null;
            selectedElementRef.current = null;
        };
    }, [isElementMode]);

    const unselectElement = () => {
        if (selectedOverlayRef.current) {
            selectedOverlayRef.current.remove();
            selectedOverlayRef.current = null;
        }
        selectedElementRef.current = null;
        setSelectedElement(null);
    };

    if (selectedElement) {
        const elementType = selectedElement.tagName.toLowerCase();
        const icon = elementTypeToIcon[elementType] || <Code className="h-3 w-3" />;
        
        return (
            <div className="flex items-center gap-2 text-xs">
                <div className="flex items-center gap-1 px-2 py-1 bg-gray-900 text-white rounded-md">
                    {icon}
                    <span>{elementType}</span>
                    <X 
                        className="h-3 w-3 cursor-pointer hover:text-gray-300" 
                        onClick={unselectElement}
                    />
                </div>
            </div>
        );
    }

    return (
        <div>
            <button
                className={`flex items-center gap-1 px-2 py-1 rounded-md text-xs transition-colors ${
                    isElementMode 
                        ? "bg-gray-900 text-white hover:bg-gray-800" 
                        : "text-gray-600 hover:bg-gray-100"
                }`}
                onClick={() => setIsElementMode(!isElementMode)}
            >
                <Pointer className="h-3 w-3" />
                <span>Select Element</span>
            </button>
        </div>
    );
}