import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { Settings, LogOut, Menu, X, MessageSquare, CreditCard, Twitter } from 'lucide-react';
import logoImage from '../../logo_v2.png';
import {MyUserAvatar} from '../UserAvatar';
import Feedback from './Feedback';
import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

const SwitchEnv = () => {
  const handleSwitchEnv = () => {
    const currentUrl = window.location.href;
    let newUrl;
    
    if (currentUrl.startsWith('https://app.base44.com/')) {
      newUrl = currentUrl.replace('https://app.base44.com/', 'http://localhost:3000/');
    } else if (currentUrl.startsWith('http://localhost:3000/')) {
      newUrl = currentUrl.replace('http://localhost:3000/', 'https://app.base44.com/');
    }

    if (newUrl) {
      window.location.href = newUrl;
    }
  };

  return (
    <div className="fixed bottom-64 left-5">
      <Tooltip>
        <TooltipTrigger asChild>
          <Button 
            variant="ghost" 
            size="icon"
            onClick={handleSwitchEnv}
            className="text-white hover:text-white hover:bg-white/10"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="24" 
              height="24" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8"/>
              <path d="M3 3v5h5"/>
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16"/>
              <path d="M16 16h5v5"/>
            </svg>
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right">
          Switch Environment
        </TooltipContent>
      </Tooltip>
    </div>
  );
};

const Sidebar = ({ pages }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout, user, isAuthenticated } = useAuth();
  const [mobileDrawerVisible, setMobileDrawerVisible] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const handleMenuClick = (key) => {
    navigate(key);
    setMobileDrawerVisible(false);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
    setMobileDrawerVisible(false);
  };

  const menuItems = pages.filter(page => !page.hide).map((page) => ({
    key: page.url,
    label: (
      <div className="flex items-center justify-center w-full h-full">
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="text-white">
              {React.cloneElement(page.icon, { size: 32 })}
            </div>
          </TooltipTrigger>
          <TooltipContent side="right">
            {page.name}
          </TooltipContent>
        </Tooltip>
        <span className="ml-3 md:hidden text-white">{page.name}</span>
      </div>
    ),
    onClick: () => handleMenuClick(page.url),
  }));

  const userSubMenuItems = [
    {
      key: 'settings',
      icon: <Settings size={32} />,
      label: 'Settings',
      onClick: () => {
        navigate('/user-settings');
        setMobileDrawerVisible(false);
      },
    },
    {
      key: 'billing',
      icon: <CreditCard size={32} />,
      label: 'Billing',
      onClick: () => {
        navigate('/billing');
        setMobileDrawerVisible(false);
      },
    },
    {
      key: 'logout',
      icon: <LogOut size={32} />,
      label: 'Sign Out',
      onClick: handleLogout,
    },
  ];

  const SidebarContent = () => (
    <div>
      <div className="h-16 flex justify-center items-center px-4">
        <img 
          src={logoImage} 
          alt="Base44 Logo" 
          className="h-12 w-auto cursor-pointer" 
          onClick={() => navigate('/')}
        />
        <Button
          variant="ghost"
          size="icon"
          className="md:hidden absolute right-2 text-white hover:text-white hover:bg-white/10"
          onClick={() => setMobileDrawerVisible(false)}
        >
          <X size={24} />
        </Button>
      </div>
      <nav className="space-y-1 p-2">
        {menuItems.map((item) => (
          <Button
            key={item.key}
            variant={location.pathname === item.key ? "secondary" : "ghost"}
            className={`w-full justify-center md:justify-start py-2 text-white hover:text-white ${location.pathname === item.key ? 'bg-white/20 hover:bg-white/20' : 'hover:bg-white/10'}`}
            onClick={item.onClick}
          >
            {item.label}
          </Button>
        ))}
      </nav>
      <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 flex flex-col gap-4 items-center">
        {isAuthenticated && (
          <>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  className="text-white hover:text-white hover:bg-white/10"
                  onClick={() => window.open('https://discord.gg/ThpYPZpVts', '_blank')}
                >
                  <svg className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515a.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0a12.64 12.64 0 0 0-.617-1.25a.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057a19.9 19.9 0 0 0 5.993 3.03a.078.078 0 0 0 .084-.028a14.09 14.09 0 0 0 1.226-1.994a.076.076 0 0 0-.041-.106a13.107 13.107 0 0 1-1.872-.892a.077.077 0 0 1-.008-.128a10.2 10.2 0 0 0 .372-.292a.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127a12.299 12.299 0 0 1-1.873.892a.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028a19.839 19.839 0 0 0 6.002-3.03a.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03z"/>
                  </svg>
                </Button>
              </TooltipTrigger>
              <TooltipContent side="right">
                Join Discord
              </TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  className="text-white hover:text-white hover:bg-white/10"
                  onClick={() => window.open('https://x.com/base_44', '_blank')}
                >
                  <Twitter className="h-6 w-6" />
                </Button>
              </TooltipTrigger>
              <TooltipContent side="right">
                Follow on Twitter
              </TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger asChild>
                <Button 
                  variant="ghost"
                  size="icon"
                  onClick={() => setFeedbackOpen(true)}
                  className="text-white hover:text-white hover:bg-white/10"
                >
                  <MessageSquare size={32} />
                </Button>
              </TooltipTrigger>
              <TooltipContent side="right">
                Give Feedback
              </TooltipContent>
            </Tooltip>

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon" className="hover:bg-white/10">
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div>
                        <MyUserAvatar />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side="right">
                      User
                    </TooltipContent>
                  </Tooltip>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-52">
                {userSubMenuItems.map((item) => (
                  <DropdownMenuItem key={item.key} onClick={item.onClick}>
                    {item.icon}
                    <span className="ml-2">{item.label}</span>
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        )}
      </div>
      { user?.platform_role === 'platform_admin' && <SwitchEnv />}
      <Feedback isOpen={feedbackOpen} onClose={() => setFeedbackOpen(false)} />
    </div>
  );

  return (
    <div className="app-sidebar">
      <Button
        variant="ghost"
        size="icon"
        className="fixed top-4 left-4 z-50 md:hidden text-white hover:text-white hover:bg-white/10"
        onClick={() => setMobileDrawerVisible(true)}
      >
        <Menu size={24} />
      </Button>

      {mobileDrawerVisible && (
        <div 
          className="fixed inset-0 bg-black/50 z-40 md:hidden" 
          onClick={() => setMobileDrawerVisible(false)}
        ></div>
      )}

      <div className={`fixed inset-y-0 left-0 z-50 w-64 bg-[#2b3440] transform ${mobileDrawerVisible ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out md:hidden shadow-lg`}>
        <SidebarContent />
      </div>

      <div className="hidden z-[2] md:block w-20 h-full relative shadow-lg bg-[#2b3440] border-r">
        <SidebarContent />
      </div>
    </div>
  );
};

export default Sidebar;
