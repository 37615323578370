import React, { useState } from 'react';
import UserAppAPI from '../../api/UserAppAPI';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useApp } from '../../context/AppContext';
import { Separator } from "@/components/ui/separator";
import { Input } from "@/components/ui/input";
import { Copy, Mail, UserPlus } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";

const InviteUserModal = ({ isVisible, onCancel, onInviteSuccess }) => {
  const {app} = useApp();
  const { toast } = useToast();
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('user');
  const appUrl = `https://app.base44.com/apps-show/${app.slug}`;

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(appUrl);
    toast({
      title: "URL Copied",
      description: "App URL has been copied to clipboard"
    });
  };

  const handleInvite = async (e) => {
    e.preventDefault();
    if (!email) {
      toast({
        title: "Error",
        description: "Please enter an email address",
        variant: "destructive"
      });
      return;
    }

    try {
      await UserAppAPI.inviteUser(app.id, email, role);
      toast({
        title: "Success",
        description: "Invitation sent successfully"
      });
      onInviteSuccess();
      onCancel();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to send invitation",
        variant: "destructive"
      });
    }
  };

  return (
    <Dialog open={isVisible} onOpenChange={onCancel}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle className="text-2xl font-semibold flex items-center gap-2">
            <UserPlus className="w-6 h-6" />
            Share App
          </DialogTitle>
          <DialogDescription>
            Invite users to use your app
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-6 py-4">
          <div className="space-y-4">
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <h4 className="text-sm font-medium">Public URL</h4>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={handleCopyUrl}
                  className="text-xs hover:text-primary"
                >
                  <Copy className="h-3 w-3 mr-1" />
                  Copy
                </Button>
              </div>
              <p className="text-sm text-muted-foreground">Share this URL with users for quick and easy access to your app</p>
            </div>
            <Input 
              readOnly 
              value={appUrl}
              className="bg-muted font-mono text-sm"
            />
          </div>

          <Separator className="my-6" />

          <form onSubmit={handleInvite} className="space-y-6">
            <div className="space-y-4">
              <div className="flex items-center">
                <Mail className="w-4 h-4 mr-2 text-muted-foreground" />
                <h4 className="text-sm font-medium">Invite via Email</h4>
              </div>
              
              <div className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="email">Email address</Label>
                  <Input
                    id="email"
                    type="email"
                    placeholder="colleague@company.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full"
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="role">Role</Label>
                  <Select value={role} onValueChange={setRole}>
                    <SelectTrigger id="role" className="w-full">
                      <SelectValue placeholder="Select a role" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="user">User</SelectItem>
                      <SelectItem value="admin">Admin</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>

            <DialogFooter className="gap-2">
              <Button variant="outline" type="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit">
                Send Invitation
              </Button>
            </DialogFooter>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InviteUserModal;
